<template lang="">
  <v-card>
    <v-card-title> </v-card-title>
    <v-card-text>
      <v-data-table  show-group-by show-expand :headers="headers" :items="desserts" :expanded="expanded" :group-by="groupBy" item-value="name" class="elevation-1">
        <template v-slot:group-header="{ item,columns, toggleGroup, isGroupOpen, isExpanded }">
          <tr >
            <td :colspan="columns.length" >
              <VBtn size="small" variant="text" :icon="isGroupOpen(item) ? '$next' : '$expand'" @click="toggleGroup(item)"></VBtn>
              {{ item.value ? "Contains gluten" : "Gluten free" }}
            </td>
          </tr>


        </template>
     
      </v-data-table>

      
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "MoneyTransactionChart",
  data() {
    return {
     
    };
  },
  mounted() {
  },
  methods: {},
};
</script>
<style lang=""></style>
