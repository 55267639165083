<template>
  <MoneyUI>
    <v-container :fluid="true" class="mt-7">
      <AccountMain></AccountMain>
    </v-container>
  </MoneyUI>
</template>
<script>
import AccountMain from "@/components/account/AccountMain.vue";
import MoneyUI from "@/components/layouts/MoneyUI.vue";
export default {
  name: "AccountPage",
  components: {
    MoneyUI,AccountMain
  },
};
</script>
<style lang=""></style>

