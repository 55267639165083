<template lang="">
  <div>
    <router-link :to="{ name: 'login' }">
      <v-btn color="error"> Login</v-btn>
    </router-link>
    <router-link :to="{ name: 'money' }">
      <v-btn color="secondary"> money</v-btn>
    </router-link>
  </div>
</template>
<script>
export default {
  name: "HomePage",
};
</script>
<style lang=""></style>
