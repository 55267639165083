<template>

    <div class="row">
      <v-col cols="12">
      <v-card>
      <v-card-title>AdminCategoryPage</v-card-title>
      </v-card>
      </v-col>
    </div>
      

</template>
<script>
import MoneyUI from "@/components/layouts/MoneyUI.vue";
export default {
  name: "AdminCategoryPage",
  components: {
    MoneyUI,
  },
};
</script>
<style lang=""></style>
