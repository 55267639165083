<template>
  <vue-progress-bar></vue-progress-bar>
  <router-view v-slot="{ Component }">
    <!-- <v-slide-x-transition> -->
      <component v-if="!loading" :is="Component" />
    <!-- </v-slide-x-transition> -->
  </router-view>
</template>

<script>
import authService from "./services/auth.service";
import { useAuthStore } from "./stores/authStore";
import { useCommonStore } from "./stores/commonStore";
import { useConfigStore } from "./stores/configStore";
import { useWalletStore } from "./stores/walletStore";

export default {
  
  name: "App",
  setup: () => ({
    
    configStore$: useConfigStore(),
    walletStore$: useWalletStore(),
    authStore$: useAuthStore(),
    commonStore$: useCommonStore(),
  }),
  components: {
    // HomePage
  },

  data: () => ({
    loading: true,
    snackbar: true,
  }),
  created() {},
  mounted() {
    var _this = this;

    //  hook the progress bar to start before we move router-view
    // this.$router.beforeEach((to, from, next) => {
    //   //  does the page we want to go to have a meta.progress object
    //   if (to.meta.progress !== undefined) {
    //     let meta = to.meta.progress;
    //     // parse meta tags
    //     this.$Progress.parseMeta(meta);
    //   }
    //   //  start the progress bar
    //   this.$Progress.start();
    //   //  continue to next page
    //   next();
    // });
    // //  hook the progress bar to finish after we've finished moving router-view
    // this.$router.afterEach((to, from) => {
    //   //  finish the progress bar
    //   // console.log(to);
    //   // this.$Progress.finish();
    // });
      _this.loadInitialFunctionStore();
  },

  methods: {
    async loadInitialFunctionStore() {
      // console.log('this.authStore$.saveRoute: ', this.authStore$.saveRoute);
    //  if(this.authStore$.saveRoute != ""){
    //   this.$router.push({name:this.authStore$.saveRoute})
    //  }
      try {
        var _this = this;
        var router = "";
        this.configStore$.loadLangAndTheme();
      
        // this.walletStore$.loadWalletsAndUser();
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
  },
  computed: {},
};
</script>
