import { DEFAULT_THEME } from "@/useConfig";
const defaultTheme = localStorage.getItem("config")?.theme ? JSON.parse(localStorage.getItem("config")).theme : DEFAULT_THEME;
const theme = {
  defaultTheme: defaultTheme,
  themes: {
    light: {
      dark: false,
      colors: {
        primary: "#9155FD",
        white: "#FFFFFF",
        whiteColor: "#000000",
        saveRed: "#FF4F57",
        secondary: "#8A8D93",
        "on-secondary": "#fff",
        success: "#56CA00",
        info: "#16B1FF",
        warning: "#FFB400",
        error: "#FF4C51",
        "on-primary": "#FFFFFF",
        "on-success": "#FFFFFF",
        "on-warning": "#FFFFFF",
        background: "#F4F5FA",
        "on-background": "#3A3541",
        "on-surface": "#3A3541",
        "grey-50": "#FAFAFA",
        "grey-100": "#F5F5F5",
        "grey-200": "#EEEEEE",
        "grey-300": "#E0E0E0",
        "grey-400": "#BDBDBD",
        "grey-500": "#9E9E9E",
        "grey-600": "#757575",
        "grey-700": "#616161",
        "grey-800": "#424242",
        "grey-900": "#212121",
      },
      variables: {
        "border-color": "#3A3541",
        "medium-emphasis-opacity": 0.68,

        // Shadows
        "shadow-key-umbra-opacity": "rgba(var(--v-theme-on-surface), 0.08)",
        "shadow-key-penumbra-opacity": "rgba(var(--v-theme-on-surface), 0.12)",
        "shadow-key-ambient-opacity": "rgba(var(--v-theme-on-surface), 0.04)",
      },
    },
    dark: {
      dark: true,
      colors: {
        primary: "#9155FD",
        white: "#312D4B",
        whiteColor: "#FFFFFF",
        saveRed: "#FF4F57",
        secondary: "#8A8D93",
        "on-secondary": "#fff",
        success: "#56CA00",
        info: "#16B1FF",
        warning: "#FFB400",
        error: "#FF4C51",
        "on-primary": "#FFFFFF",
        "on-success": "#FFFFFF",
        "on-warning": "#FFFFFF",
        background: "#28243D",
        "on-background": "#E7E3FC",
        surface: "#312D4B",
        "on-surface": "#E7E3FC",
        "grey-50": "#2A2E42",
        "grey-100": "#2F3349",
        "grey-200": "#4A5072",
        "grey-300": "#5E6692",
        "grey-400": "#7983BB",
        "grey-500": "#8692D0",
        "grey-600": "#AAB3DE",
        "grey-700": "#B6BEE3",
        "grey-800": "#CFD3EC",
        "grey-900": "#E7E9F6",
      },
      variables: {
        "border-color": "#E7E3FC",
        "medium-emphasis-opacity": 0.68,

        // Shadows
        "shadow-key-umbra-opacity": "rgba(20, 18, 33, 0.08)",
        "shadow-key-penumbra-opacity": "rgba(20, 18, 33, 0.12)",
        "shadow-key-ambient-opacity": "rgba(20, 18, 33, 0.04)",
      },
    },
  },
};
export default theme;
