<template lang="">
    <v-navigation-drawer location="left" full-height style="height: 100%" id="no-background-hover" elevation="1" :permanent="display$.smAndUp.value ? true : false" rail>
      <v-list class="mt-3" density="compact" nav>
        <v-list-item link :to="{ name: ROUTER_CHAT_INBOX }" :value="ROUTER_CHAT_INBOX"  active-color="blue">
          <v-tooltip location="left">
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props">mdi-message-text-outline</v-icon>
            </template>
            {{ $t("chatInbox") }}
          </v-tooltip>
        </v-list-item>
        <v-list-item link :to="{ name: ROUTER_CHAT_FRIEND }" :value="ROUTER_CHAT_FRIEND" active-color="blue">
          <v-tooltip location="left">
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props">mdi-contacts</v-icon>
            </template>
            {{ $t("chatFriend") }}
          </v-tooltip>
        </v-list-item>
      </v-list>
      <template v-slot:append> </template>
    </v-navigation-drawer>
</template>
<script>
import { mapState } from "pinia";
import { useTheme, useDisplay } from "vuetify";
import { useAuthStore } from "@/stores/authStore";
import { useToast } from "vue-toastification";
import { useConfigStore } from "@/stores/configStore";
export default {
  name: "ChatSidebar",
  setup: () => ({
    theme$: useTheme(),
    auth$: useAuthStore(),
    toast$: useToast(),
    display$: useDisplay(),
  }),
  components: {},
  data: () => ({}),
  created() {},
  mounted() {},
  methods: {},
  watch: {},
  computed: {},
};
</script>
