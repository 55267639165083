<template>
  <UserList v-if="$route.name == ROUTER_ADMIN_USER"></UserList>
  <RouterView v-else></RouterView>
</template>
<script>
import MoneyUI from "@/components/layouts/MoneyUI.vue";
import UserList from "./user/UserList.vue";
export default {
  name: "AdminUserPage",
  components: {
    MoneyUI,
    UserList,
  },
};
</script>
<style lang=""></style>
