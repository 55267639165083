import Api from "@/utils/axios";

export default {
  users(filter = null) {
    return Api.post("/user/doAction/admin", { action: "users", ...filter });
  },
  user(id) {
    return Api.post("/user/doAction/admin", { action: "by-id", id:id });
  },
  save(data) {
    return Api.post("/user/doAction/admin", { action: "save", ...data });
  },
  update(data) {
    return Api.post("/user/doAction/admin", { action: "update", ...data });
  },
  viewPassword(id) {
    return Api.post("/user/doAction/admin", { action: "view-pass", id:id  });
  },
  remove(id) {
    return Api.post("/user/doAction/admin", { action: "remove", id:id });
  },
};
