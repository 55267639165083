import Api from "@/utils/axios";

export default {
  icon(filter = null) {
    return Api.post("/icon/doAction/admin", { action: "icons", ...filter });
  },
  save(data) {
    return Api.request({
      method: "post",
      url: "/icon/doAction/admin",
      data: { action: "save", ...data },
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  update(data) {
    return Api.request({
      method: "post",
      url: "/icon/doAction/admin",
      data: { action: "update", ...data },
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  byId(id) {
    return Api.post("/icon/doAction/admin", { action: "by-id", id:id });
  },
  remove(id) {
    return Api.post("/icon/doAction/admin", { action: "remove", id:id });
  },

};
