<template lang="">
  <v-app-bar :elevation="$route.name == ROUTER_CHAT_INBOX || $route.name == ROUTER_CHAT_FRIEND ? 1 : 3" density="compact" class="pt-1 pb-1">
    <template v-slot:prepend>
      <v-app-bar-nav-icon ref="check" @click="openMenuPhone()" class="d-lg-none" color="secondary"></v-app-bar-nav-icon>

      <v-list-item
        @click="showDiaLogMain()"
        class="hover-shadow hover-pointer nav-item-money"
        :prepend-avatar="`${baseUrl}/images/icons/${auth$.icon?.value ? auth$.icon.value : defaultIcon}`"
        subtitle=""
      >
        <v-list-item-title class=""
          ><span class="b-350">{{ $t("balanceMainAccount") }}</span>
        </v-list-item-title>
        <v-list-item-subtitle>
          <!--         
        <span class="b-450">{{ formatNumber(walletStore$.money_total) }}</span> /
          <span class="b-450 text-info">{{ formatNumber(walletStore$.money_real) }}</span> -->

          <ElementTotalMoney :styleText="``" :moneyTotal="walletStore$.money_total" :moneyReal="walletStore$.money_real"></ElementTotalMoney>
        </v-list-item-subtitle>
      </v-list-item>

      <v-list-item
        v-if="this.isShowNabar"
        @click="dialog = true"
        class="hover-shadow hover-pointer nav-item-money d-none d-sm-flex"
        :prepend-avatar="`${baseUrl}/images/icons/${walletStore$.walletSelected?.icon ? walletStore$.walletSelected.icon.value : defaultIcon}`"
        subtitle=""
      >
        <v-list-item-title class=""
          ><span class="b-350">{{ walletStore$.walletSelected.name }}</span>
        </v-list-item-title>
        <v-list-item-subtitle
          ><span class="b-450">{{ this.formatNumber(walletStore$.walletSelected.money_total) }}</span> /
          <span class="b-450 text-info">{{ this.formatNumber(walletStore$.walletSelected.money_real) }}</span>
        </v-list-item-subtitle>
      </v-list-item>
    </template>
    <v-app-bar-title>
      <v-expand-x-transition>
        <v-text-field
          min-height="10px"
          hide-details
          rows="1"
          underlined
          density="compact"
          v-show="expandSearch"
          single-line
          :label="$t('appBarSearch') + '...'"
        ></v-text-field>
      </v-expand-x-transition>
    </v-app-bar-title>

    <template v-slot:append>
      <v-btn class="d-none d-sm-block" @click="changeTheme()" color="default" icon>
        <v-icon>{{ configStore$.theme == "light" ? "mdi-weather-night" : "mdi-weather-sunny" }}</v-icon>
      </v-btn>
      <v-btn class="d-none d-sm-block" @click="changeLang()" color="default">{{ configStore$.lang == "en" ? "VN" : "EN" }}</v-btn>
    </template>
  </v-app-bar>
  <LogMain @closeDiaLogMain="closeDiaLogMain()" :isShowDialogLogMain="isShowDialogLogMain"></LogMain>
  <v-bottom-navigation v-if="this.isShowNabar" class="d-flex d-sm-none">
    <v-dialog v-model="dialog" fullscreen :scrim="false" transition="dialog-bottom-transition">
      <template v-if="$route.name == ROUTER_CHAT_INBOX || $route.name == ROUTER_CHAT_FRIEND" v-slot:activator="{ props }">
        <v-list-item link :to="{ name: ROUTER_CHAT_INBOX }" :value="ROUTER_CHAT_INBOX"  active-color="blue">
          <v-tooltip location="left">
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props">mdi-message-text-outline</v-icon>
            </template>
            {{ $t("chatInbox") }}
          </v-tooltip>
        </v-list-item>
        <v-list-item link :to="{ name: ROUTER_CHAT_FRIEND }" :value="ROUTER_CHAT_FRIEND" active-color="blue">
          <v-tooltip location="left">
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props">mdi-contacts</v-icon>
            </template>
            {{ $t("chatFriend") }}
          </v-tooltip>
        </v-list-item>

    


      </template>

      <template v-else v-slot:activator="{ props }">
        <v-list-item
          v-bind="props"
          class="hover-pointer nav-item-money pa-0"
          :prepend-avatar="`${baseUrl}/images/icons/${walletStore$.walletSelected?.icon ? walletStore$.walletSelected.icon.value : defaultIcon}`"
          subtitle=""
        >
          <v-list-item-title class=""
            ><span class="b-350">{{ walletStore$.walletSelected.name }}</span>
          </v-list-item-title>
          <v-list-item-subtitle
            ><span class="b-450">{{ this.formatNumber(walletStore$.walletSelected.money_total) }}</span> /
            <span class="b-450 text-info">{{ this.formatNumber(walletStore$.walletSelected.money_real) }}</span>
          </v-list-item-subtitle>
        </v-list-item>
      </template>


      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="dialog = false">
            <v-icon :color="configStore$.theme == 'light' ? 'white' : 'whiteColor'">mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t("SideBarWallet") }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-list lines="two">
          <v-list-item
            v-for="item in walletStore$.wallets"
            :key="item.id"
            @click="handleSetWalletSelected(item)"
            class="hover-pointer nav-item-money pa-3 save-active-hover"
            :prepend-avatar="`${baseUrl}/images/icons/${item.icon.value}`"
            subtitle=""
          >
            <v-list-item-title class=""
              ><span class="b-350">{{ item.name }}</span></v-list-item-title
            >
            <v-list-item-subtitle
              ><span class="b-450">{{ this.formatNumber(item.money_total) }}</span> /
              <span class="b-450 text-info">{{ this.formatNumber(item.money_real) }}</span>
            </v-list-item-subtitle>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
      </v-card>
    </v-dialog>
  </v-bottom-navigation>
</template>
<script>
import { useConfigStore } from "@/stores/configStore";
import { useWalletStore } from "@/stores/walletStore";
import { DEFAULT_LANG } from "@/useConfig";
import { useTheme } from "vuetify/lib/framework.mjs";
import ElementTotalMoney from "./elements/ElementTotalMoney.vue";
import LogMain from "./log/LogMain.vue";
export default {
  name: "MoneyUI",
  setup: () => ({
    configStore$: useConfigStore(),
    theme$: useTheme(),
    walletStore$: useWalletStore(),
  }),
  components: { LogMain, ElementTotalMoney },
  data: () => ({
    show: false,
    expandSearch: false,
    dialog: false,
    isShowDialogLogMain: false,
    displayNabar: false,
  }),
  created() {
    let width = window.innerWidth;
    if (width < 1280) {
      this.configStore$.closeDrawerMenu();
    }
  },
  mounted() {},
  methods: {
    closeDiaLogMain() {
      this.isShowDialogLogMain = false;
    },
    async showDiaLogMain() {
      // await this.walletStore$.loadWalletsAndUser();
      this.walletStore$.loadLogs();
      this.isShowDialogLogMain = true;
    },
    handleSetWalletSelected(item) {
      this.walletStore$.setSelectedWallet(item);
      this.$router.push({ name: "money", params: { walletId: item.id } });
      this.dialog = false;
    },
    openMenuPhone() {
      this.configStore$.setDrawerState();
    },
    changeTheme() {
      if (this.configStore$.theme == "light") {
        this.configStore$.setTheme("dark");
      } else {
        this.configStore$.setTheme("light");
      }
      this.theme$.global.name.value = this.configStore$.theme;
    },
    changeLang() {
      if (this.configStore$.lang == DEFAULT_LANG) {
        this.configStore$.setLang("en");
      } else {
        this.configStore$.setLang(DEFAULT_LANG);
      }
    },
  },
  watch: {
    walletStore$: function (newValue) {
      console.log(newValue);
    },
  },
  computed: {
    isShowNabar() {
      var flag = false;
      if (this.walletStore$.wallets.length > 0) {
        this.walletStore$.wallets.map((item) => {
          if (!flag) {
            if (item.status == "publish") {
              flag = true;
            }
          }
        });
      }
      return flag;
    },
  },
};
</script>
<style>
#no-background-hover::before {
  background-color: transparent !important;
}
</style>
