import Api from "@/utils/axios";

export default {
  wallets(params) {
    return Api.post("/wallet/doAction", { action: "wallets", ...params });
  },

  getDataCreate() {
    return Api.post("/wallet/doAction", { action: "wallet-data-create" });
  },
  save(data) {
    return Api.post("/wallet/doAction", { action: "save", ...data });
  },
  update(wallet, wallet_id) {
    return Api.post("/wallet/doAction", { action: "update", wallet_id: wallet_id, ...wallet });
  },
  convertRemainMoney(wallet_id) {
    return Api.post("/wallet/doAction", { action: "convert-remain-money", wallet_id: wallet_id });
  },
  delete(wallet_id) {
    return Api.post("/wallet/doAction", { action: "delete", wallet_id: wallet_id });
  },
  changeStatus(wallet_id, status) {
    return Api.post("/wallet/doAction", { action: "change-status", wallet_id: wallet_id, status: status });
  },
  updateWalletSelect(wallet_id) {
    return Api.post("/wallet/doAction", { action: "update-wallet-selected", wallet_id: wallet_id });
  },
  updateUserBalance(data) {
    return Api.post("/user/doAction", { action: "update-money", ...data });
  },
  changePublicWallet(data) {
    return Api.post("/wallet/doAction", { action: "change-public-wallet", ...data });
  },
  publicWallet(mail, walletId, filter) {
    return Api.post("/public", { mail: mail, walletId: walletId, ...filter });
  },
};
