import Api from "@/utils/axios";

export default {
  goldens() {
    return Api.post("/golden/doAction", { action: "goldens" });
  },

  prices(data) {
    return Api.post("/golden/doAction", { action: "prices", ...data });
  },
  goldenCharts(from, to) {
    return Api.post("/golden/doAction", { action: "golden-charts", from: from, to: to });
  },

  save(data) {
    return Api.post("/golden/doAction", { action: "save", ...data });
  },
  crawlSJCData() {
    return Api.post("/golden/doAction", { action: "crawl-SJC-prices" });
  },
  crawlPNJData() {
    return Api.post("/golden/doAction", { action: "crawl-PNJ-prices" });
  },
  profitHistories() {
    return Api.post("/golden/doAction", { action: "profit-histories" });
  },
  delete(id) {
    return Api.post("/golden/doAction", { action: "delete", golden_id: id });
  },
  update(data) {
    return Api.post("/golden/doAction", { action: "update", ...data });
  },
  updateGoldenCurrentProces() {
    return Api.post("/golden/doAction", { action: "update-golden-current-prices" });
  },
  updateField(id, field, value) {
    return Api.post("/golden/doAction", { action: "update-field", id: id, field: field, value: value });
  },
};
