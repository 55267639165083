<template lang="">
  <v-navigation-drawer
    :style="renderCssSideBarAdmin()"
    location="right"
    id="no-background-hover"
    elevation="1"
    rail
    v-model="configStore$.drawerState"
  >
    <v-list class="mt-3" density="compact" nav>
      <!-- <v-list-item link :to="{ name: ROUTER_ADMIN }" :value="ROUTER_ADMIN" active-color="saveRed">
        <v-tooltip location="left">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props">mdi mdi-crown-outline</v-icon>
          </template>
          {{ $t("admin") }}
        </v-tooltip>
      </v-list-item> -->
      <v-list-item link :to="{ name: ROUTER_ADMIN_USER }" :value="ROUTER_ADMIN_USER" value="users" active-color="saveRed">
        <v-tooltip location="left">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props">mdi mdi-account-supervisor-outline</v-icon>
          </template>
          {{ $t("adminUserPage") }}
        </v-tooltip>
      </v-list-item>
      <v-list-item link :to="{ name: ROUTER_ADMIN_ICON }" :value="ROUTER_ADMIN_ICON" active-color="saveRed">
        <v-tooltip location="left">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props">mdi mdi-image-area</v-icon>
          </template>
          {{ $t("adminIconPage") }}
        </v-tooltip>
      </v-list-item>
      <v-list-item link :to="{ name: ROUTER_ADMIN_CATEGORY }" :value="ROUTER_ADMIN_CATEGORY" active-color="saveRed">
        <v-tooltip location="left">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props">mdi mdi-format-list-bulleted-type</v-icon>
          </template>
          {{ $t("adminCategoryPage") }}
        </v-tooltip>
      </v-list-item>
    </v-list>
    <template v-slot:append> </template>
  </v-navigation-drawer>
</template>
<script>
import authService from "@/services/auth.service";
import { useAuthStore } from "@/stores/authStore";
import { useConfigStore } from "@/stores/configStore";
import { encryptStorage } from "@/utils/encryptLocalStorage";
import { useToast } from "vue-toastification";
import { DEFAULT_LANG, ROUTER_ACCOUNT } from "@/useConfig";
import { useDisplay, useTheme } from "vuetify/lib/framework.mjs";
import allStore from "@/stores/allStore";

export default {
  setup: () => ({
    auth$: useAuthStore(),
    display$: useDisplay(),
    toast$: useToast(),
    configStore$: useConfigStore(),
    theme$: useTheme(),
    walletStore$: useTheme(),
  }),
  name: "SideBarAdmin",
  components: {},
  data: () => ({
    drawerState: true,
  }),
  mounted() {
  },
  methods: {
    renderCssSideBarAdmin() {
      let css = "";
      if (this.display$.mdAndDown.value) {
        css += "z-index:1010;";
      }
      return css;
    },
    changeTheme() {
      if (this.configStore$.theme == "light") {
        this.configStore$.setTheme("dark");
      } else {
        this.configStore$.setTheme("light");
      }
      this.theme$.global.name.value = this.configStore$.theme;
    },
    changeLang() {
      if (this.configStore$.lang == DEFAULT_LANG) {
        this.configStore$.setLang("en");
      } else {
        this.configStore$.setLang(DEFAULT_LANG);
      }
    },
    async logout() {
      try {
        await authService.logout(this.auth$.accessToken);
        encryptStorage.removeItem("auth");
        allStore.logout();
        this.toast$.info(this.$t("logout"));
        this.$router.push({ name: "login" });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style>
#no-background-hover::before {
  background-color: transparent !important;
}
.custom-icon-logout i {
  transform: rotate(180deg);
}
.save-avatar {
  margin: 7px 7px 7px 8px;
}
</style>
