<template>
  <v-row>
    <v-col cols="12">
      <v-card class="">
        <v-card-title>
          <v-row>
            <v-col cols="12" lg="12">
              <v-row class="mx-auto">
                <v-col cols="12" lg="6">
                  <v-text-field
                    @change="changeDatePrice()"
                    class="mx-auto"
                    style="width: 200px"
                    :label="$t('MoneyTransactionFrom') + '*'"
                    name="dateCreate"
                    v-model="fromPrice"
                    type="date"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    @change="changeDatePrice()"
                    class="mx-auto"
                    style="width: 200px"
                    :label="$t('MoneyTransactionTo') + '*'"
                    name="dateCreate"
                    v-model="toPrice"
                    type="date"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    clearable
                    @change="changeDataSJCChart()"
                    v-model="selectedPriceChart"
                    :items="arraySelectPriceChart"
                    label="Select Golden Type"
                    chips
                    multiple
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-chart v-if="!loadingSJC" style="height: 550px" :option="chartPriceSJC" autoresize />
        <v-progress-circular class="d-block ma-auto mb-3 mt-10" :size="80" v-else color="amber" indeterminate></v-progress-circular>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent, GridComponent, ToolboxComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, provide, reactive, onMounted, watch, watchEffect, defineProps } from "vue";
import { useCommonStore } from "@/stores/commonStore";
import { useAuthStore } from "@/stores/authStore";
import { useConfigStore } from "@/stores/configStore";
import goldenService from "@/services/golden.service";
import i18n from "@/langs/i18n";
import { useDisplay, useLocale, useTheme } from "vuetify";
import { dateFormatSaveMoney } from "@/useConfig";
import { useGoldenStore } from "@/stores/goldenStore";
use([CanvasRenderer, LineChart, TitleComponent, BarChart, TooltipComponent, LegendComponent, GridComponent, ToolboxComponent]);
const props = defineProps(["goldenTypes", "temp"]);
const authStore = useAuthStore();
const configStore = useConfigStore();
const theme = useTheme();
const goldenStore = useGoldenStore();
const display = useDisplay();
const locale = useLocale();
const from = ref("");
const to = ref("");
const fromPrice = ref("");
const loadingSJC = ref(false);
const arraySelectPriceChart = ref([]);
const selectedPriceChart = ref([]);
const toPrice = ref("");
const loading = ref(true);
const goldens = ref({});
const checkObject = reactive({ id: 1, price: 1000 });
const themeConfig = configStore.theme;
const SJC = "sjc";
const PNJ = "pnj";
const listSJCSell = ref();
const listSJCBuy = ref();

const chartPriceSJC = ref({
  title: {
    text: "",
    subtext: "Triệu",
  },
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: [],
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    data: ["Buy", "Sell"],
  },
  yAxis: {
    type: "value",
    // axisLine: { onZero: false },
    // min: 70000000,
    // max: 90000000,
    axisLabel: {
      marginBottom: 300,
      fontSize: 15,
      formatter: (val) => `${parseInt(val / 1000000)}`,
    },
  },
  series: [],
});

const getGoldPriceList = async (sources) => {
  loadingSJC.value = true;
  var sjc = [];
  var sjcDate = [];
  var sjcName = [];
  var pnj = [];
  var seriesSJCSell = [];
  var seriesSJCBuy = [];
  //   chartPriceSJC.value = [];
  var request = await goldenService.prices({ ...sources });
  let arrayDataBuy = [];
  let arrayDataSell = [];
  if (request.success) {
    if (request.prices.length == 0) {
      chartPriceSJC.value.series = [
        {
          name: "",
          type: "line",
          smooth: false,
          data: [],
        },
      ];
    }
    sjc = request.prices.filter((x) => x.source == SJC);
    pnj = request.prices.filter((x) => x.source == PNJ);
    sjc.map((item) => {
      sjcDate.push(item.createdAt);
    });
    sjcDate = [...new Set(sjcDate)];
    chartPriceSJC.value.xAxis.data = sjcDate;
    sjcDate.map((item, index) => {
      let sjcDateFilter = sjc.filter((x) => x.createdAt == item);
      seriesSJCBuy = [];
      seriesSJCSell = [];
      arrayDataBuy = [];
      arrayDataSell = [];
      sjcDateFilter.map((item) => {
        let element = {
          name: item.name,
          type: "line",
          smooth: false,
          data: [],
        };
        arrayDataBuy.push([]);
        arrayDataSell.push([]);
        seriesSJCBuy.push(element);
        seriesSJCSell.push(element);
      });
    });
    sjcDate.map((item, index) => {
      let sjcDateFilter = sjc.filter((x) => x.createdAt == item);

      sjcDateFilter.map((sjcElement, sjcIndex) => {
        arrayDataBuy[sjcIndex].push(sjcElement.buyValue);
        arrayDataSell[sjcIndex].push(sjcElement.sellValue);
      });
    });

    let dataSerialBuy = [];
    let dataSerialSell = [];

    seriesSJCBuy.map((item, index) => {
      arraySelectPriceChart.value.push(item.name);
      dataSerialBuy.push({ ...item, data: arrayDataBuy[index] });
      dataSerialSell.push({ ...item, data: arrayDataSell[index] });
    });

    listSJCBuy.value = dataSerialBuy;
    listSJCSell.value = dataSerialSell;

    let dataFilter = [];
    var allValueSeries = [];
    selectedPriceChart.value.map((item) => {
      let filterSJCBUY = dataSerialBuy.filter((x) => x.name == item);
      let filterSJCSell = dataSerialSell.filter((x) => x.name == item);

      filterSJCBUY.map((buy) => {
        dataFilter.push({ ...buy, name: "Buy_" + buy.name });
      });
      filterSJCSell.map((sell) => {
        dataFilter.push({ ...sell, name: "Sell_" + sell.name });
      });
      chartPriceSJC.value.legend.data.push("Buy_" + item);
      chartPriceSJC.value.legend.data.push("Sell_" + item);
    });
    chartPriceSJC.value.series = dataFilter;

    if (dataFilter.length > 0) {
      dataFilter.map((item) => {
        if (item.data) {
          item.data.map((element) => {
            allValueSeries.push(element);
          });
        }
      });
    }
  }
  allValueSeries = allValueSeries.sort().reverse();
  if (allValueSeries.length > 0) {
    chartPriceSJC.value.yAxis.max = parseInt(allValueSeries[0]) + 5000000;
    chartPriceSJC.value.yAxis.min = parseInt(allValueSeries[allValueSeries.length - 1]) - 5000000;
  }
  loadingSJC.value = false;
};
onMounted(() => {
  if (!props.temp) {
    provide(THEME_KEY, "light");
    let date = new Date();
    date.setMonth(date.getMonth() - 2);
    from.value = dateFormatSaveMoney(date.toDateString());
    fromPrice.value = dateFormatSaveMoney(date.toDateString());
    to.value = dateFormatSaveMoney(new Date());
    toPrice.value = dateFormatSaveMoney(new Date());
    selectedPriceChart.value = props.goldenTypes;
    getGoldPriceList({ source: [SJC], from: fromPrice.value, to: toPrice.value });
  }
});

const changeDate = () => {
  loadGoldenChart();
};

// watch(props.goldenTypes,()=>{
//   console.log("treo",props.goldenTypes)
// })

watch(selectedPriceChart, (new_value, old_value) => {
  loadingSJC.value = true;
  chartPriceSJC.value.series = [];
  chartPriceSJC.value.legend.data = [];
  var allValueSeries = [];
  if (listSJCBuy.value && listSJCSell.value) {
    let dataFilter = [];
    selectedPriceChart.value.map((item) => {
      let filterSJCBUY = listSJCBuy.value.filter((x) => x.name == item);
      let filterSJCSell = listSJCSell.value.filter((x) => x.name == item);

      filterSJCBUY.map((buy) => {
        dataFilter.push({ ...buy, name: "Buy_" + buy.name });
      });
      filterSJCSell.map((sell) => {
        dataFilter.push({ ...sell, name: "Sell_" + sell.name });
      });
      chartPriceSJC.value.legend.data.push("Buy_" + item);
      chartPriceSJC.value.legend.data.push("Sell_" + item);
    });
    chartPriceSJC.value.series = dataFilter;
    if (dataFilter.length > 0) {
      dataFilter.map((item) => {
        if (item.data) {
          item.data.map((element) => {
            allValueSeries.push(element);
          });
        }
      });
    }
  }
  allValueSeries = allValueSeries.sort().reverse();
  if (allValueSeries.length > 0) {
    chartPriceSJC.value.yAxis.max = parseInt(allValueSeries[0]) + 5000000;
    chartPriceSJC.value.yAxis.min = parseInt(allValueSeries[allValueSeries.length - 1]) - 5000000;
  }
  setTimeout(() => {
    loadingSJC.value = false;
  }, 50);

  // chartPriceSJC.value.legend.data = [];
});
const changeDatePrice = () => {
  getGoldPriceList({ source: [SJC], from: fromPrice.value, to: toPrice.value });
};
</script>

<style scoped>
.chart {
  height: 100vh;
}
</style>
