<template>
  <div class="row">
    <v-col cols="12">
      <v-card>
        <v-card-title>AdminDashboardPage</v-card-title>
      </v-card>
    </v-col>
  </div>
</template>
<script>
import MoneyUI from "@/components/layouts/MoneyUI.vue";
export default {
  name: "AdminDashboardPage",
  components: {
    MoneyUI,
  },
};
</script>
<style lang=""></style>
