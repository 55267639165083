import Api from "@/utils/axios";

export default {
  categories() {
    return Api.post("/category/doAction", { action: "categories" });
  },
  transactions(query) {
    return Api.post("/transaction/doAction", { action: "transactions-by-wallet", ...query });
  },
  delete(id) {
    return Api.post("/transaction/doAction", { action: "delete", transaction_id:id });
  },
  save(data) {
    return Api.request({
      method: "post",
      url: "/transaction/doAction",
      data: { action: "save", ...data },
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  update(data) {
    return Api.request({
      method: "post",
      url: "/transaction/doAction",
      data: { action: "update", ...data },
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
};
