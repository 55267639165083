<template lang="">
  <v-progress-circular
    class="d-block ma-auto mb-3 mt-10"
    :size="size ? size : 80"
    :color="color ? color : 'saveRed'"
    indeterminate
  ></v-progress-circular>
</template>
<script>
import { integer } from "@vuelidate/validators";

export default {
  props: { color: "red", size: integer },
};
</script>
<style lang=""></style>
