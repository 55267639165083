<template lang="">
  <v-dialog scrollable v-model="moneyStore$.dialogDetail" :width="widthDialog">
    <v-col cols="12" transition="v-slide-y-transition" class="d-flex-start justify-center">
      <v-card>
        <v-card-title>
          <div class="justify-space-center">
            <v-icon class="" size="25" @click="moneyStore$.setDialogDetail(false)"> mdi mdi-close</v-icon>
            <span class="ml-5"
              ><span>{{ moneyStore$.transaction.note }}</span></span
            >
          </div>
        </v-card-title>

        <v-card-title class="text-h5">
          <v-row>
            <v-col cols="1">
              <v-img
                :src="`${baseUrl}/images/icons/${
                  moneyStore$.transaction.category?.icon ? moneyStore$.transaction.category?.icon.value : defaultIcon
                }`"
                :lazy-src="`${baseUrl}/images/icons/${
                  moneyStore$.transaction.category?.icon ? moneyStore$.transaction.category?.icon.value : defaultIcon
                }`"
                height="50"
                width="50"
                class="hover-pointer mr-1 ml-1"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0">
                    <v-progress-circular indeterminate color="grey-lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
            <v-col cols="9" class="ml-2">
              <span class="align-self-start" :class="moneyStore$.transaction.amount >= 0 ? 'text-green' : 'text-red'">
                {{ formatNumber(moneyStore$.transaction.amount) }}
              </span>
              <br />
              <span class="text-subtitle-2 align-self-start">{{ moneyStore$.transaction.createdDate }}</span>
              <v-divider></v-divider>
              <v-card-subtitle class="mt-4 ma-auto ml-0 pa-0 mb-3" style="white-space: break-spaces">
                {{ moneyStore$.transaction.description }}
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-card-title>

        <div v-if="moneyStore$.transaction.images.length > 0" class="mt-3">
          <v-row :style="`height:${heightContent}px;overflow-y: scroll;`">
            <template v-for="img in moneyStore$.transaction.images" :key="img.id">
              <v-col :key="img.id" v-if="this.getExtensionFile(img.name) != 'pdf'" class="" cols="4">
                <v-dialog :key="img.id" scrollable transition="dialog-top-transition" width="850">
                  <template :key="img.id" v-slot:activator="{ props }">
                    <!-- <v-btn color="primary" v-bind="props">From the top</v-btn> -->
                    <v-img
                      v-bind="props"
                      :src="baseUrl + '/images/transaction/' + img.name"
                      :lazy-src="baseUrl + '/images/transaction/' + img.name"
                      aspect-ratio="1"
                      style="width:250px;height:250px"
                      class="bg-grey-lighten-2 hover-pointer ma-3"
                    >
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="grey-lighten-5"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </template>
                  <template :key="img.id" v-slot:default="{ isActive }">
                    <v-card class="ma-0 pa-0">
                      <v-card-text class="ma-0 pa-0">
                        <v-img
                          :src="baseUrl + '/images/transaction/' + img.name"
                          :lazy-src="baseUrl + '/images/transaction/' + img.name"
                          aspect-ratio="1"
           
                          class="bg-grey-lighten-2 hover-pointer save-image-preview"
                        >
                          <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                              <v-progress-circular indeterminate color="grey-lighten-5"></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <v-icon
                          @click="viewImage(baseUrl + '/images/transaction/' + img.name)"
                          class="save-image-preview-download money-hover-text"
                          >mdi mdi-download</v-icon
                        >
                        <v-icon @click="isActive.value = false" class="save-image-preview-close money-hover-text"
                          >mdi mdi-close</v-icon
                        >
                      </v-card-text>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
            </template>

            <v-col cols="12">
              <template v-for="img in moneyStore$.transaction.images" :key="img.id">
                <v-chip v-if="this.getExtensionFile(img.name) == 'pdf'" class="ma-2 d-inline-flex">
                  <span class="money-hover-text" @click="viewImage(baseUrl + '/images/transaction/' + img.name)">
                    {{ img.name }}</span
                  >
                  <v-icon
                    @click="viewImage(baseUrl + '/images/transaction/' + img.name, true)"
                    class="ml-1 hover-pointer money-hover-text"
                    size="x-small"
                    icon="mdi mdi-download"
                  ></v-icon>
                </v-chip>
              </template>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-dialog>
</template>
<script>
import { useAuthStore } from "@/stores/authStore";
import { useWalletStore } from "@/stores/walletStore";
import { useToast } from "vue-toastification";
import { useDisplay, useTheme } from "vuetify/lib/framework.mjs";
import { useMoneyStore } from "@/stores/moneyStore";
export default {
  setup: () => ({
    theme$: useTheme(),
    display$: useDisplay(),
    auth$: useAuthStore(),
    toast$: useToast(),
    walletStore$: useWalletStore(),
    moneyStore$: useMoneyStore(),
  }),
  name: "MoneyTransactionDetail",
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      transaction: "",
      // baseUrl: process.env.VUE_APP_BASE_URL,
      dialogPreviewImage: false,
      widthDialog: "",
      heightContent: "",
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.display$.lgAndUp) {
      this.widthDialog = this.display$.width.value - this.display$.width.value / 10;
    }
    //this.heightContent = this.display$.height.value - this.display$.height.value * 0.2;
  },
  mounted() {},
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    displayWidthDetail() {
      let width = 0;
      let css = "";
      if (this.window.width > 600 && this.window.width < 1280) {
        width = 38;
        css = `position:fixed;width:${width}%`;
        return css;
      } else if (this.window.width > 1280) {
        width = 23;
        css = `position:fixed;width:${width}%`;
        return css;
      }
      return "width:100%";
    },
    viewImage(pathImage, download = false) {
      window.open(pathImage, "_blank");
    },
    getExtensionFile(name) {
      try {
        return name.split(".").pop();
      } catch (e) {
        return "";
      }
    },
  },

  computed: {},
};
</script>
