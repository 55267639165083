<template lang="">
  <v-row class="">
    <v-col class="pa-0" cols="12" lg="3" sm="5">
      <v-card>
        <v-list active-color="primary" class="v-list-chatinbox" lines="three">
          <v-list-item v-for="(item, i) in items" :key="i" :value="item.title" active-color="primary" :prependAvatar="item.prependAvatar">
            <template v-slot:title>
              <div v-html="item.title"></div>
            </template>
            <template v-slot:subtitle>
              <div v-html="item.subtitle"></div>
            </template>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
    <v-col class="pa-0" cols="12" lg="9" sm="7">
      <ChatMessage></ChatMessage>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from "pinia";
import { useTheme, useDisplay } from "vuetify";
import { useAuthStore } from "@/stores/authStore";
import { useToast } from "vue-toastification";
import { useConfigStore } from "@/stores/configStore";
import ChatMessage from "./ChatMessage.vue";
export default {
  name: "ChatInbox",
  setup: () => ({
    theme$: useTheme(),
    auth$: useAuthStore(),
    toast$: useToast(),
    display$: useDisplay(),
  }),
  components: { ChatMessage },
  data: () => ({
    scrollInvoked: 0,
    items: [
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "Brunch this weekend?",
        subtitle: `<span class="text-primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
      },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: "Summer BBQ",
        subtitle: `<span class="text-primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
      },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
        title: "Oui oui",
        subtitle: '<span class="text-primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
      },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
        title: "Birthday gift",
        subtitle: '<span class="text-primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
      },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Recipe to try",
        subtitle: '<span class="text-primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
      },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Recipe to try",
        subtitle: '<span class="text-primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
      },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Recipe to try",
        subtitle: '<span class="text-primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
      },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Recipe to try",
        subtitle: '<span class="text-primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
      },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Recipe to try",
        subtitle: '<span class="text-primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
      },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Recipe to try",
        subtitle: '<span class="text-primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
      },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "Brunch this weekend?",
        subtitle: `<span class="text-primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
      },
    ],
  }),
  created() {},
  mounted() {
    console.log(this.display$.smAndUp.value);
  },
  methods: {
    onScroll() {
      this.scrollInvoked++;
    },
  },
  watch: {},
  computed: {},
};
</script>
<style></style>
