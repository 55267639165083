import { defineStore } from "pinia";
import walletService from "@/services/wallet.service";
import { router } from "@/routes";
const nameStore = "public";

export const usePublicStore = defineStore(nameStore, {
  state: () => ({
    loading: true,
    success: false,
    filters: {},
    wallet: "",
    filterIconByCategory: [],
    amountPositive: 0,
    amountNegative: 0,
    transactions: [],
    dialogDetail:false,
    transaction: "",
    mail:"",
    walletId:"",
    user: {
      name: "",
    },
  }),

  actions: {
    setDialogDetail(value) {
      this.dialogDetail = value;
    },
    async loadingTransactionStore(mail, walletId, filters=null) {
      var _this = this;
      this.loading = true;
      await walletService.publicWallet(mail, walletId, {...filters}).then((walletResponse) => {
        _this.loading = false;
        if (walletResponse.success) {
          var handleDataTransactions = [];
          var tempArrayCategory = [];
          _this.amountPositive = walletResponse.amountPositiveTransaction;
          _this.amountNegative = walletResponse.amountNegativeTransaction;
          if (walletResponse.transactions.length > 0) {
            walletResponse.transactions.map((item) => {
              if (item.amount < 0) {
                item.color = "red-lighten-2";
              } else if (item.amount > 0) {
                item.color = "green";
              } else {
                item.color = "cyan";
              }
              tempArrayCategory.push(item.category.id);
              handleDataTransactions.push(item);
            });
          }

          if (walletResponse.fullCategories.length > 0) {
            _this.filterIconByCategory = walletResponse.fullCategories;
          }
          _this.user = walletResponse.user;
          _this.wallet = walletResponse.wallet;
          _this.transactions = handleDataTransactions;
          _this.success = walletResponse.success;
        }
      });
    },
    setTransaction(transaction) {
      this.transaction = transaction;
    },
    setKeyPublic(mail,walletId) {
      this.mail = mail;
      this.walletId = walletId;
    },
    setLoading(value) {
      this.loading = value;
    },
    clearFilter() {
      this.filters = {};
      this.loadingTransactionStore(this.mail,this.walletId,{})
    },
  },
});
