import { defineStore } from "pinia";
import { encryptStorage } from "@/utils/encryptLocalStorage";
import walletService from "@/services/wallet.service";
import { useWalletStore } from "./walletStore";
import moneyService from "@/services/money.service.js";
import VueCommon from "@/utils/vueCommon";
const nameStore = "money";
export const useMoneyStore = defineStore(nameStore, {
  state: () => ({
    transactions: [],
    loading: false,
    filterIconByCategory: [],
    amountPositive: 0,
    amountNegative: 0,
    transaction: "",
    dialogDetail:false,
  }),

  actions: {
    loadTransactionDetail(id) {
      var _this = this;
      this.transactions.map((item) => {
        if (item.id == id) {
          _this.transaction = item;
        }
      });
    },
    setTransaction(item) {
      this.transaction = item;
    },
    setDialogDetail(value) {
      this.dialogDetail = value;
    },
    async loadTransaction(wallet_id, query = null, setTransactionId = null) {
      var _this = this;
      this.loading = true;
      this.filterIconByCategory = [];
      moneyService.transactions({ wallet_id: wallet_id, ...query }).then(
        (transactionsRequest) => {
          if (transactionsRequest.success) {
            var handleDataTransactions = [];
            var tempArrayCategory = [];
            _this.amountPositive = transactionsRequest.amountPositiveTransaction;
            _this.amountNegative = transactionsRequest.amountNegativeTransaction;
            if (transactionsRequest.transactions.length > 0) {
              transactionsRequest.transactions.map((item) => {
                let imagesFlex = VueCommon.methods.calImagesFlex(item.images);
                item.images = imagesFlex;
                if (setTransactionId) {
                  if (setTransactionId == item.id) {
                    this.transaction = item;
                  }
                }
                if (item.amount < 0) {
                  item.color = "red-lighten-2";
                } else if (item.amount > 0) {
                  item.color = "green";
                } else {
                  item.color = "cyan";
                }
                tempArrayCategory.push(item.category.id);
                handleDataTransactions.push(item);
              });
            }
            if (transactionsRequest.fullCategories.length > 0) {
              _this.filterIconByCategory = transactionsRequest.fullCategories;
            }

            _this.transactions = handleDataTransactions;
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
    },
  },
});
