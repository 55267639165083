<template lang="">
  <v-card :disabled="moneyStore$.loading" style="width: 100%">
    <template v-slot:title>
      <div class="d-flex justify-space-between pa-3">
        <div class="justify-space-center">
          <v-icon class="" size="25" @click="$router.push({ name: 'money' })"> mdi mdi-close</v-icon>
          <span class="ml-5"
            ><span>{{ walletStore$.walletSelected.name }}</span></span
          >
          <v-tooltip v-if="walletStore$.walletSelected.is_public" location="top">
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props" size="small" class="hover-pointer ml-4" color="info">mdi mdi-earth</v-icon>
            </template>
            This wallet published
          </v-tooltip>
        </div>

        <div class="justify-space-between">
          <MoneyAdd v-if="!isWaitingAdd" @removeProps="removeProps()" :editTransaction="editTransaction" :categories="categories">
            <v-btn
              @click="clearFilter()"
              :variant="'text'"
              v-if="isShowButtonFilter"
              color="secondary"
              icon="mdi-filter-remove-outline"
            >
            </v-btn>
            <v-menu location="start">
              <template v-slot:activator="{ props }">
                <v-btn color="back" icon="mdi-dots-vertical" v-bind="props" variant="text"> </v-btn>
              </template>
              <v-list density="compact" class="pa-0">
                <v-list-item
                  class="save-active-hover hover-pointer"
                  active-color="primary"
                  @click="dialogPublic = !dialogPublic"
                  >{{$t('publicWallet')}}
                </v-list-item>
                <v-list-item class="save-active-hover hover-pointer" @click="editWallet()" active-color="primary">{{$t('editWallet')}}</v-list-item>
              </v-list>
            </v-menu>
          </MoneyAdd>
        </div>
      </div>
      <v-divider></v-divider>
      <v-row class="pa-4">
        <v-col cols="6">
          <div class="d-flex justify-space-between">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <i
                  v-bind="props"
                  @click="filterTransaction('in')"
                  class="text-body-1 text-decoration-underline hover-pointer text-green money-hover-text"
                  >{{ $t("MoneyTransactionInflow") }}</i
                >
              </template>
              <span>{{ $t("MoneyTransactionInflowDescription") }}</span>
            </v-tooltip>
            <p class="text-body-1 mb-2 text-green">
              <span v-if="moneyStore$.amountPositive > 0">+</span>{{ formatNumber(moneyStore$.amountPositive) }}
            </p>
          </div>
          <div class="d-flex justify-space-between">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <i
                  v-bind="props"
                  @click="filterTransaction('out')"
                  class="text-body-1 text-decoration-underline hover-pointer text-red money-hover-text"
                  >{{ $t("MoneyTransactionOutflow") }}</i
                >
              </template>
              <span>{{ $t("MoneyTransactionOutflowDescription") }}</span>
            </v-tooltip>
            <p class="text-body-1 text-red">
              <span v-if="moneyStore$.amountPositive < 0">-</span>{{ formatNumber(moneyStore$.amountNegative) }}
            </p>
          </div>

          <div class="d-flex justify-space-between">
            <span class="text-body-1"></span>
            <span class="text-body-1 save-total-transaction text-cyan"
              ><span class="text-cyan" v-if="moneyStore$.amountPositive + moneyStore$.amountNegative > 0">+</span
              >{{ formatNumber(moneyStore$.amountPositive + moneyStore$.amountNegative) }}</span
            >
          </div>
          <v-divider class="mt-2 mb-2"></v-divider>
          <div cols="12" class="d-flex flex-wrap">
            <v-tooltip v-for="item in moneyStore$.filterIconByCategory" :key="item">
              <template v-slot:activator="{ props }">
                <img
                  @click="filterTransaction('category', item)"
                  v-bind="props"
                  elevation="10"
                  class="hover-pointer mr-1 ml-1"
                  :class="item.id == $route.query.cID ? 'select-transaction-category' : ''"
                  style="width: 50px; height: 50px"
                  :src="`${baseUrl}/images/icons/${item.icon ? item.icon.value : defaultIcon}`"
                />
              </template>
              <span v-if="configStore$.lang == LANG_EN">{{ item.nameTranslate }}</span>
              <span v-else>{{ item.name }}</span>
            </v-tooltip>
          </div>
        </v-col>

        <v-divider vertical></v-divider>
        <v-col cols="6" class="pr-0">
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" lg="6" class="pa-3">
                  <v-text-field
                    v-model="from"
                    @change="filterTransaction('from')"
                    :label="$t('MoneyTransactionFrom')"
                    type="date"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" class="pa-3">
                  <v-text-field
                    v-model="to"
                    @change="filterTransaction('to')"
                    :label="$t('MoneyTransactionTo')"
                    type="date"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="12" class="pa-3">
                  <v-text-field
                    v-model="query"
                    @change="filterTransaction('query')"
                    :label="$t('MoneyTransactionSearch') + '...'"
                    type="text"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="d-flex flex-wrap">
              <v-tooltip location="bottom">
                <template v-slot:activator="{ props }">
                  <v-btn
                    @click="handleButtonFilter('day')"
                    v-bind="props"
                    c
                    class="mr-2"
                    icon="mdi mdi-calendar-today "
                    size="small"
                    :variant="$route.query.state == 'day' ? 'flat' : 'outlined'"
                    color="info"
                  >
                  </v-btn>
                </template>
                <span>{{ $t("MoneyTransactionToday") }}</span>
              </v-tooltip>

              <v-tooltip>
                <template v-slot:activator="{ props }">
                  <v-btn
                    @click="handleButtonFilter('month')"
                    tooltip="34343"
                    v-bind="props"
                    class="mr-2"
                    icon="mdi mdi-calendar-month-outline"
                    size="small"
                    :variant="$route.query.state == 'month' ? 'flat' : 'outlined'"
                    color="info"
                  >
                  </v-btn>
                </template>
                <span>{{ $t("MoneyTransactionMonth") }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12"> </v-col>
          </v-row>
        </v-col>
        <v-divider v-if="moneyStore$.transactions.length > 0 && !moneyStore$.loading"></v-divider>
        <v-col v-if="moneyStore$.transactions.length > 0 && !moneyStore$.loading" cols="12">
          <div cols="12" class="d-flex flex-wrap">
            <v-tabs class="ma-auto" v-model="tabTransaction" color="saveRed" align-tabs="center">
              <v-tab :value="1">News Feed</v-tab>
              <v-tab :value="2">{{ $t("MoneyTransactionTimeline") }}</v-tab>
              <v-tab :value="3">{{ $t("MoneyTransactionTable") }}</v-tab>
              <!-- <v-tab :value="3">{{ $t("MoneyTransactionChart") }}</v-tab> -->
            </v-tabs>
          </div>
        </v-col>
      </v-row>
      <v-divider v-if="tabTransaction != 1"></v-divider>

      <div
        v-if="moneyStore$.transactions.length == 0 && !moneyStore$.loading"
        data-v-71b42fb1=""
        class="transaction-empty"
      >
        <div data-v-71b42fb1="" class="moneylover-empty">
          <span data-v-71b42fb1="" class="smile all">^_^</span> <span data-v-71b42fb1="" class="note"></span>
          <span data-v-71b42fb1="" class="tips"
            ><span data-v-71b42fb1="" class="note">{{ $t("MoneyTransactionEmpty") }}</span></span
          >
        </div>
      </div>
    </template>
    <v-progress-circular
      class="d-block ma-auto mb-3 mt-10"
      :size="80"
      v-if="moneyStore$.loading"
      color="amber"
      indeterminate
    ></v-progress-circular>
    <template v-if="tabTransaction != 1" v-slot:text>
      <v-window
        v-if="moneyStore$.transactions.length > 0 && !moneyStore$.loading"
        :touch="false"
        v-model="tabTransaction"
      >
        <v-window-item :value="1"> </v-window-item>
        <v-window-item :value="2">
          <v-timeline align="start" side="end">
            <v-timeline-item
              class="save-active-hover"
              block
              style="width: 100%"
              :dot-color="item.color"
              v-for="item in moneyStore$.transactions"
              size="small"
            >
              <v-row
                class="d-flex save-active-hover hover-pointer pt-2 pl-2 rounded"
                :class="moneyStore$.transaction?.id == item.id ? 'save-active-bg' : ''"
              >
                <v-col @click="detailTranscation(item)" cols="12">
                  <v-row>
                    <v-col cols="12" sm="5" lg="3" class="pa-0">
                      <strong class="mr-2 pa-0" style="width: 130px">
                        {{ item.createdDate }}
                        <p :class="`text-${item.color}`">
                          <span :class="`text-${item.color}`" v-if="item.amount > 0">+</span
                          >{{ formatNumber(item.amount) }}
                        </p>
                        <p class="text-red">
                          <v-icon @click="remove(item)">mdi mdi-delete</v-icon>
                          <v-icon @click="edit(item)" color="cyan">mdi mdi-pencil</v-icon>
                        </p>
                      </strong>
                    </v-col>
                    <v-col cols="12" sm="7" lg="9" class="pa-0">
                      <strong>
                        <span class="money-hover-text">
                          <span class="text-cyan-lighten-2"> {{ item.category.name }}</span> / {{ item.note }}</span
                        >
                      </strong>
                      <strong> </strong>

                      <div class="text-caption" style="white-space: break-spaces">
                        <v-chip size="small" v-if="item.images.length > 0" color="info" label text-color="white">
                          <v-icon start icon="mdi-image"></v-icon>
                          {{ item.images.length }}
                        </v-chip>
                        <v-chip
                          size="small"
                          color="info"
                          v-if="item.description"
                          class="ml-1 mr-1"
                          label
                          text-color="white"
                        >
                          <v-icon class="ma-auto" start icon="mdi-format-text"></v-icon>
                        </v-chip>
                        {{
                          item.description?.length >= 30
                            ? item.description.toString().substring(0, 30) + "...."
                            : item.description
                        }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-window-item>
        <v-window-item :value="3">
          <MoneyTransactionTable
            @tableActionRemove="tableActionRemove($event)"
            @tableActionEdit="tableActionEdit($event)"
            @tableActionDetail="tableActionDetail($event)"
          ></MoneyTransactionTable>
        </v-window-item>
        <!-- <v-window-item :value="3"> <MoneyTransactionChart></MoneyTransactionChart> </v-window-item> -->
      </v-window>
    </template>
  </v-card>

  <v-row v-if="tabTransaction == 1" class="mt-5">
    <v-col     v-for="item in moneyStore$.transactions" cols="12">
      <v-card class="mx-auto">
        <v-list-item class="w-100">
          <template v-slot:prepend>
            <v-avatar :image="baseUrl + '/images/icons/' + ( item.category.icon ?  item.category.icon.value : defaultIcon) "></v-avatar>
          </template>
          <v-list-item-title>
            <strong>
              <span class="text-cyan-lighten-2 text-subtitle-1 font-weight-medium"> {{ item.category.name }}</span> /
              <span class="money-hover-text text-subtitle-1 font-weight-medium">{{ item.note }}</span>
              <span class="ml-5" :class="`text-${item.color}`">
                <span :class="`text-${item.color}`" v-if="item.amount > 0">+</span>{{ formatNumber(item.amount) }}
              </span>
            </strong>
          </v-list-item-title>
          <v-list-item-subtitle> {{ item.createdDate }}</v-list-item-subtitle>
          <template v-slot:append>
            <v-menu location="start">
              <template v-slot:activator="{ props }">
                <v-btn color="secondary" icon="mdi-dots-horizontal" v-bind="props" variant="text"> </v-btn>
              </template>
              <v-list density="compact" class="pa-0">
                <v-list-item
                  class="save-active-hover hover-pointer text-capitalize"
                  @click="remove(item)"
                  active-color="primary"
                  >{{ $t("delete") }}
                </v-list-item>
                <v-list-item
                  class="save-active-hover hover-pointer text-capitalize"
                  @click="edit(item)"
                  active-color="primary"
                  >{{ $t("edit") }}
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-list-item>
        <v-row dense class="pl-4 pr-4 pb-4 pt-2">
          <v-divider v-if="item.description" class=""></v-divider>
          <v-card-text v-if="item.description" class="pl-0 pr-0 text-body-1">{{ item.description }} </v-card-text>
          <v-divider v-if="item.images.length >0" class="mb-4"></v-divider>
          <v-col    class="" v-for="image in item.images" :key="image.id" :cols="image.flex">
            <v-dialog
              v-if="!allowINotmages.includes(filenameExtension(image.name))"
              scrollable
              width="1200"
              transition="dialog-top-transition"
            >
              <template :key="img.id" v-slot:activator="{ props }">
                <v-img
                  v-bind="props"
                  :src="`${baseUrl}/images/${
                    !allowINotmages.includes(filenameExtension(image.name)) ? 'transaction' : 'icons'
                  }/${!allowINotmages.includes(filenameExtension(image.name)) ? image.name : ''}`"
                  :lazy-src="`${baseUrl}/images/${
                    !allowINotmages.includes(filenameExtension(image.name)) ? 'transaction' : 'icons'
                  }/${!allowINotmages.includes(filenameExtension(image.name)) ? image.name : ''}`"
                  class="align-end"
                  max-height="350"
                ></v-img>
              </template>
              <template :key="img.id" v-slot:default="{ isActive }">
                <v-card class="ma-0 pa-0">
                  <v-card-text class="ma-0 pa-0">
                    <img
                      :src="`${baseUrl}/images/${
                        !allowINotmages.includes(filenameExtension(image.name)) ? 'transaction' : 'icons'
                      }/${!allowINotmages.includes(filenameExtension(image.name)) ? image.name : ''}`"
                      :lazy-src="`${baseUrl}/images/${
                        !allowINotmages.includes(filenameExtension(image.name)) ? 'transaction' : 'icons'
                      }/${!allowINotmages.includes(filenameExtension(image.name)) ? image.name : ''}`"
                      class="align-end"
                      style="width:100%;height:100%"
                    />
                    <v-icon
                      @click="viewImage(baseUrl + '/images/transaction/' + image.name)"
                      class="save-image-preview-download money-hover-text"
                      >mdi mdi-download</v-icon
                    >
                    <v-icon @click="isActive.value = false" class="save-image-preview-close money-hover-text"
                      >mdi mdi-close</v-icon
                    >
                  </v-card-text>
                </v-card>
              </template>
            </v-dialog>

            <v-col v-else cols="12" class="">
              <a
                target="_blank"
                :href="`${baseUrl}/images/transaction/${image.name}`"
                class="text-h5 text-uppercase mt-8 d-flex money-hover-text justify-center"
                ><v-icon>mdi-file-document</v-icon>{{ filenameExtension(image.name) }}</a
              >
            </v-col>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>

  <VueConfirm class="mt-3" ref="confirm"></VueConfirm>
  <v-dialog v-model="dialogPublic" width="750">
    <v-card>
      <v-card-title>
        <div class="d-flex justify-space-between">
          <div class="justify-space-center">
            <span class="ml-5"><span> Public Configuration</span></span>
          </div>
          <v-btn
            variant="text"
            class="float-right"
            color="secondary"
            @click="dialogPublic = false"
            icon="mdi mdi-close"
          ></v-btn>
        </div>
        <v-divider></v-divider>
      </v-card-title>
      <v-card-text>
        <v-switch v-model="walletStore$.walletSelected.is_public" label="Public"></v-switch>
        <div v-if="walletStore$.walletSelected.is_public">
          <v-text-field v-model="isPublicLink" readonly block label="Link">
            <template v-slot:append-inner>
              <v-icon @click="copyWindow()" class="hover-pointer money-hover-text">mdi mdi-content-copy</v-icon>
            </template>
          </v-text-field>
          <p class="text-grey-darken-1 text-caption">(*)Everyone can be access by link</p>
        </div>
      </v-card-text>
      <br />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialogPublic = false" variant="flat" color="info">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <MoneyTransactionDetail></MoneyTransactionDetail>
</template>
<script>
import moneyService from "@/services/money.service.js";
import walletService from "@/services/wallet.service.js";
import { useAuthStore } from "@/stores/authStore";
import { useMoneyStore } from "@/stores/moneyStore";
import { useWalletStore } from "@/stores/walletStore";
import { useToast } from "vue-toastification";
import { useTheme } from "vuetify/lib/framework.mjs";
import MoneyAdd from "./MoneyAdd.vue";
import VueConfirm from "../layouts/commons/VueConfirm.vue";
import MoneyTransactionDetail from "./MoneyTransactionDetail.vue";
import MoneyTransactionTable from "./MoneyTransactionTable.vue";
import MoneyTransactionChart from "./MoneyTransactionChart.vue";
import { useConfigStore } from "@/stores/configStore";
export default {
  // emits: ["loadTransactions"],
  setup: () => ({
    theme$: useTheme(),
    auth$: useAuthStore(),
    toast$: useToast(),
    walletStore$: useWalletStore(),
    configStore$: useConfigStore(),
    moneyStore$: useMoneyStore(),
  }),
  props: { transactions: Array, loading: true },
  components: { MoneyAdd, VueConfirm, MoneyTransactionDetail, MoneyTransactionTable, MoneyTransactionChart },
  name: "MoneyTransaction",
  firstLoadPage: true,
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      tabTransaction: "",
      baseUrl: process.env.VUE_APP_BASE_URL,
      dialogPreviewImage: false,
      viewTimeline: true,
      buttonFilter: false,
      isPublicWallet: 1,
      isPublicLink: "",
      dialogPublic: false,
      isShowDialogDetail: false,
      isShowButtonFilter: false,
      isWaitingAdd:true,
      editTransaction: "",
      categories:"",

      from: "",
      to: "",
      query: "",
      filter: {},
      buttonFilter: "",
    };
  },
  created() {

    window.addEventListener("resize", this.handleResize);
    
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  async mounted() {
    this.tabTransaction = this.auth$.setting.tabTransaction
    if (this.$route.query.to) {
      this.to = this.$route.query.to;
    }

    if (this.$route.query.from) {
      this.from = this.$route.query.from;
    }

    if (this.$route.query.query) {
      this.query = this.$route.query.query;
    }
    if (!_.isEmpty(this.$route.query)) {
      this.isShowButtonFilter = true;
    } else {
      this.isShowButtonFilter = false;
    }
    await this.moneyStore$.loadTransaction(this.walletStore$.walletSelected.id, this.$route.query);
    if (this.$route.params.transactionId) {
      this.moneyStore$.loadTransactionDetail(this.$route.params.transactionId);
    }
    if (this.walletStore$.walletSelected.is_public) {
      this.isPublicLink = this.generatePublicLink();
    }
    this.isWaitingAdd = false
  },

  methods: {
    async loadDataCreator() {
      this.isShowTransactionPanel = true
      var categoryRequest = await moneyService.categories();
      if (categoryRequest.success) {
        this.categories = categoryRequest.data;
      }
    },
    filenameExtension(value) {
      return value.split(".").pop();
    },
    async remove(item) {
      this.$swal({
        title: "Are you sure?",
        text: "delete this transaction with title " + item.note,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          var removeRequest = await moneyService.delete(item.id);
          if (removeRequest.success) {
            this.moneyStore$.loadTransaction(this.walletStore$.walletSelected.id);
            this.walletStore$.loadWalletsAndUser();
            this.toast$.success(
              this.$t("fieldSuccess", { field: this.$t("MoneyTransaction"), action: this.$t("actionRemove") })
            );
            this.$router.push({ name: "money", params: { walletId: this.$route.params.walletId }, query: this.filter });
          }
        }
      });
    },
    async changePublicWallet(data) {
      var request = await walletService.changePublicWallet(data);
      if (request.success) {
      } else {
        this.toast$.error(this.$t("someThingWrong"));
      }
    },
    copyWindow() {
      const clipboardData =
        event.clipboardData || window.clipboardData || event.originalEvent?.clipboardData || navigator.clipboard;
      if (this.isPublicWallet && this.isPublicLink) {
        clipboardData.writeText(this.isPublicLink);
        this.toast$.info("copied", {
          position: "bottom-center",
          timeout: 1106,
          closeButton: "button",
        });
      }
    },
    tableActionDetail(value) {
      this.detailTranscation(value);
    },
    tableActionRemove(value) {
      this.remove(value);
    },
    tableActionEdit(value) {
      this.edit(value);
    },
    detailTranscation(item, mobile = false) {
      this.moneyStore$.setTransaction(item);
      this.moneyStore$.setDialogDetail(true);
      // this.$router.push({
      //   name: "money",
      //   params: { walletId: this.$route.params.walletId, transactionId: item.id },
      //   query: this.filter,
      // });
    },
    handleButtonFilter(state) {
      var oldState = this.buttonFilter;

      this.buttonFilter = state;
      this.isShowButtonFilter = true;
      let tempDate = new Date(this.dateFormatCurrent());
      if (state == this.filter.state) {
        delete this.filter.from;
        delete this.filter.to;
        delete this.filter.state;
        return this.$router.push({
          name: "money",
          params: { walletId: this.$route.params.walletId },
          query: this.filter,
        });
      }

      if (state == "month") {
        tempDate.setMonth(tempDate.getMonth() - 1);
        this.filter.to = this.dateFormatCurrent();
        this.filter.state = "month";
        this.filter.from = this.dateFormatSaveMoney(tempDate);
      } else if (state == "day") {
        tempDate.setDate(tempDate.getDate());
        this.filter.to = this.dateFormatCurrent();
        this.filter.from = this.dateFormatSaveMoney(tempDate);
        this.filter.state = "day";
      }
      this.$router.push({ name: "money", params: { walletId: this.$route.params.walletId }, query: this.filter });
    },

    edit(item) {
      this.editTransaction = item;
    },
    editWallet(){
      this.$router.push({ name: "wallet", params: { walletId: this.$route.params.walletId },query:{edit:this.$route.params.walletId} });
    },
    removeProps() {
      this.editTransaction = {};
    },
    filterTransaction(state, item = null) {
      if (state == "category") {
        if (this.filter.cID == item.id) {
          delete this.filter.cID;
        } else {
          this.filter.cID = item.id;
        }
      }
      if (state == "in" || state == "out") {
        if (this.filter.io == state) {
          delete this.filter.io;
        } else {
          this.filter.io = state;
        }
      }
      if (state == "to") {
        if (this.to) {
          this.filter.to = this.to;
        } else {
          delete this.filter.to;
        }
      }

      if (state == "from") {
        if (this.from) {
          this.filter.from = this.from;
        } else {
          delete this.filter.from;
        }
      }
      if (state == "query") {
        if (this.query) {
          this.filter.query = this.query;
        } else {
          delete this.filter.query;
        }
      }
      if (!_.isEmpty(this.filter)) {
        this.isShowButtonFilter = true;
      } else {
        this.isShowButtonFilter = false;
      }
      this.$router.push({ name: "money", params: { walletId: this.$route.params.walletId }, query: this.filter });
    },
    clearFilter() {
      this.filter = {};
      this.buttonFilter = false;
      this.$router.push({ name: "money", params: { walletId: this.$route.params.walletId }, query: this.filter });
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    viewImage(pathImage, download = false) {
      window.open(pathImage, "_blank");
    },
    getExtensionFile(name) {
      try {
        return name.split(".").pop();
      } catch (e) {
        return "";
      }
    },
    generatePublicLink() {
      let email = this.auth$.email;
      let nameSplit = email.split("@");
      return nameSplit
        ? window.location.host + "/public/" + nameSplit[0] + "@" + this.walletStore$.walletSelected.id
        : false;
    },
  },
  watch: {
    "walletStore$.walletSelected.is_public": function (newValue) {
      if (newValue) {
        this.isPublicLink = this.generatePublicLink();
      } else {
        this.isPublicLink = "";
      }
      if (this.dialogPublic)
        this.changePublicWallet({ walletId: this.walletStore$.walletSelected.id, state: newValue });
    },
    "$route.params.walletId": function (new_value) {
      if (new_value) {
        this.moneyStore$.loadTransaction(new_value);
      }
    },
    "tabTransaction": function (new_value) {
      if(new_value)
      this.auth$.updateSetting({tabTransaction:new_value})
    },
    "$route.query": function (new_value) {
      if (!_.isEmpty(new_value)) {
        this.isShowButtonFilter = true;
      }
      if (!_.isEmpty(this.filter) || this.buttonFilter == false) {
        this.moneyStore$.loadTransaction(this.walletStore$.walletSelected.id, new_value);
      }
    },
  },
};
</script>
<style lang=""></style>
