<template>
  <MoneyUI>
    <ChatSidebar></ChatSidebar>
    <div :fluid="true"  class="container-chat">
        <router-view transition="fade-transition"> </router-view>
    </div>
  </MoneyUI>
</template>
<script>
import MoneyUI from "@/components/layouts/MoneyUI.vue";
import ChatPage from "@/pages/ChatPage.vue";
import ChatSidebar from "./ChatSidebar.vue";
import ChatInbox from "./ChatInbox.vue";
export default {
  name: "ChatUI",
  components: {
    MoneyUI,
    ChatPage,
    ChatSidebar,
    ChatInbox,
  },
};
</script>
<style lang=""></style>
