import { defineStore } from "pinia";
import { encryptStorage } from "@/utils/encryptLocalStorage";
import walletService from "@/services/wallet.service";
import logService from "@/services/log.service";
import common from "@/utils/vueCommon";
import {router} from '@/routes'
const nameStore = "wallet";

export const useWalletStore = defineStore(nameStore, {
  state: () => ({
    wallets: [],
    walletSelected: {
      icon: {
        value: "",
      },
      // is_public: false,
    },
    money_total: 0,
    money_real: 0,
    logs: {
      data: [],
      pagination: {
        current_page: 1,
        total: 0,
        per_page: 10,
        last_page: 1,
      },
    },
  }),

  actions: {
    setMoneyTotal(value) {},
    setMoneyReal(value) {},
    async loadLogs(filter = null) {
      var logsRequest = await logService.logs(filter);
      if (logsRequest.success) {
        common.methods.mappingResponse(logsRequest.logs, this.logs);
        return true;
      }
      return false;
    },
    async setSelectedWallet(item) {
      if (item.id == this.walletSelected.id) {
        return;
      }
      this.walletSelected = item;
      try {
        await walletService.updateWalletSelect(item.id);
      } catch (e) {
        console.log(e);
      }
    },
    resetWallet() {
      this.wallets = [];
      (this.walletSelected = {
        icon: {
          value: "",
        },
      }),
        (this.money_total = 0);
      this.money_real = 0;
    },
    async loadWalletsAndUser(status = "publish") {
      this.wallets = [];
      // this.money_real = 0;
      // this.money_total = 0;
      this.walletSelected = {
        icon: {
          value: "",
        },
      };
      var walletRequest = await walletService.wallets({ status: status });
      var flagWalletSelected = false;
      var _this = this;
      if (walletRequest?.success) {
        if (walletRequest.wallets.length > 0) {
          this.wallets = walletRequest.wallets;
          this.wallets.map((item) => {
            item.is_public = item.is_public == 1 ? true : false;
            if (!flagWalletSelected) {
              if (item.is_selected) {
                _this.walletSelected = item;
                flagWalletSelected = true;
              }
            }
          });
          if (!flagWalletSelected) {
            this.walletSelected = walletRequest.wallets[0];
          }
        }
        this.money_real = walletRequest.walletUser.money_real;
        this.money_total = walletRequest.walletUser.money_total;
      }
    },
  },
});
