<template lang="">
  <v-row class="justify-center">
    <v-col sm="10" lg="8">
      <v-card>
        <v-card-title class="text-capitalize"> {{ $t("information") }} {{ $t("SideBarAccount") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form @submit.prevent="submit()">
            <v-row class="pa-2">
              <v-col cols="2" class="justify-center">
                <v-dialog max-width="1000" scrollable transition="dialog-bottom-transition">
                  <template v-slot:activator="{ props }">
                    <v-img
                      v-bind="props"
                      @click="isShowModalIcons = !isShowModalIcons"
                      elevation="10"
                      class="hover-pointer hover-shadow ma-auto"
                      style="width: 50px; height: 50px"
                      :src="`${baseUrl}/images/icons/${form.icon}`"
                    ></v-img>
                  </template>
                  <template v-slot:default="{ isActive }">
                    <v-card>
                      <v-toolbar color="white">
                        <v-btn icon color="whiteColor" @click="isActive.value = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("walletCreateTitleIcon") }}</v-toolbar-title>
                      </v-toolbar>
                      <v-divider></v-divider>
                      <v-card-text>
                        <v-row>
                          <v-col
                            @click="selectIconWallet(icon, isActive)"
                            v-for="icon in commonStore$.icons"
                            :key="icon.id"
                            cols="3"
                            md="1"
                            lg="1"
                            class="save-icon-image"
                            :class="form.icon == icon.value ? 'save-icon-image-active' : ''"
                          >
                            <img
                              class="hover-pointer"
                              style="width: 50px; height: 50px"
                              :src="`${baseUrl}/images/icons/${icon.value}`"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <!-- </v-col>
                      </v-row> -->
                  </template>
                </v-dialog>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  v-model="v$.form.name.$model"
                  :error-messages="
                    v$.form.name.required.$invalid && v$.form.name.$dirty
                      ? $t('validRequired', { field: $t('signUpName') })
                      : ''
                  "
                  @input="v$.form.name.$touch()"
                  @blur="v$.form.name.$touch()"
                  name="name"
                  clearable
                  :label="$t('signUpName') + '*'"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.email"
                  name="email"
                  readonly
                  disabled
                  :label="$t('signUpEmail')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.phone"
                  name="phone"
                  readonly
                  disabled
                  :label="$t('signUpPhone')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-switch v-model="isChangePassword" :label="`${$t('change')} ${$t('signUpPassword')}`"></v-switch>
              </v-col>
              <v-slide-y-transition>
                <v-col v-if="isChangePassword" cols="12" sm="6">
                  <v-text-field
                    name="password"
                    v-model="v$.form.password.$model"
                    :error-messages="
                      v$.form.password.required.$invalid && v$.form.password.$dirty
                        ? $t('validRequired', { field: $t('signUpPassword') })
                        : ''
                    "
                    :label="$t('signUpPassword')"
                    @input="v$.form.password.$touch()"
                    @blur="v$.form.password.$touch()"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    :append-inner-icon="isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                    @click:append-inner="isPasswordVisible = !isPasswordVisible"
                  ></v-text-field>
                </v-col>
              </v-slide-y-transition>

              <v-slide-y-transition>
                <v-col v-if="isChangePassword" cols="12" sm="6">
                  <v-text-field
                    name="confirmPassword"
                    v-model="v$.form.confirmPassword.$model"
                    :label="$t('signUpPasswordConfirm')"
                    :error-messages="errorPasswordConfirm()"
                    @input="v$.form.confirmPassword.$touch()"
                    @blur="v$.form.confirmPassword.$touch()"
                    :type="isPasswordConfirmVisible ? 'text' : 'password'"
                    :append-inner-icon="isPasswordConfirmVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                    @click:append-inner="isPasswordConfirmVisible = !isPasswordConfirmVisible"
                  ></v-text-field>
                </v-col>
              </v-slide-y-transition>
              <v-col cols="12">
                <v-switch v-model="form.isMoneyView" :label="` ${$t('moneyView')}`"></v-switch>
              </v-col>
              <v-col cols="12">
                <v-btn type="submit" class="float-right" color="info"> <v-icon>mdi-content-save</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { useAuthStore } from "@/stores/authStore";
import { useCommonStore } from "@/stores/commonStore";
import { useWalletStore } from "@/stores/walletStore";
import useVuelidate from "@vuelidate/core";
import { useToast } from "vue-toastification";
import { useTheme } from "vuetify/lib/framework.mjs";
import { required, sameAs, helpers } from "@vuelidate/validators";
import authService from "@/services/auth.service";
export default {
  setup: () => ({
    theme$: useTheme(),
    v$: useVuelidate(),
    auth$: useAuthStore(),
    toast$: useToast(),
    walletStore$: useWalletStore(),
    commonStore$: useCommonStore(),
  }),
  name: "AccountMain",
  data() {
    return {
      isShowModalIcons: false,
      isPasswordVisible: false,
      isPasswordConfirmVisible: false,
      isChangePassword: false,
      form: {
        icon: "icon_153.png",
        icon_id: "109",
        name: "",
        email: "",
        phone: "",
        password: "",
        isMoneyView: false,
        confirmPassword: "",
      },
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        password: {
          required,
        },
        confirmPassword: {
          required,
          sameAsPassword: helpers.withMessage("", sameAs(this.form.password)),
        },
      },
    };
  },
  mounted() {
    this.form.name = this.auth$.name;
    this.form.email = this.auth$.email;
    this.form.phone = this.auth$.phone;
    this.form.icon = this.auth$.icon?.value ? this.auth$.icon.value : this.defaultIcon;
    this.form.icon_id = this.auth$.icon?.value ? this.auth$.icon.id : this.defaultIcon;
    this.form.isMoneyView = this.auth$.setting?.isMoneyView ? true : false;
  },
  methods: {
    async submit() {
      var _this = this;
      await this.v$.form.$validate();
      if (this.form.icon == this.defaultIcon) {
        _this.toast$.error(_this.$t("mustChangeIcon"));
      }
      if (this.v$.form.name.$invalid) {
        return;
      }
      if (this.isChangePassword) {
        if (this.v$.form.password.$invalid || this.v$.form.confirmPassword.$invalid) {
          return;
        }
      }
      if (this.form.icon != this.defaultIcon) {
        this.$swal({
          title: this.$t("swalSure"),
          text: this.$t("confirmUpdateField", { field: this.$t("SideBarAccount") }),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("ok"),
          cancelButtonText: this.$t("no"),
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            let user = {
              iconId: this.form.icon_id,
              name: this.form.name,
              isMoneyView: this.form.isMoneyView,
              setting: { isMoneyView: this.form.isMoneyView },
            };
            if (this.isChangePassword) {
              user.password = this.form.password;
            }
            var responseAPI = await authService.changeInformationAccount(user);
            if (responseAPI.success) {
              user.icon = responseAPI?.icon ? responseAPI.icon : null;
              _this.auth$.setUserAfterChange(user);
              _this.isChangePassword = false;
              _this.form.password = "";
              _this.form.confirmPassword = "";
              _this.toast$.success(
                _this.$t("fieldSuccess", { field: _this.$t("SideBarAccount"), action: _this.$t("actionUpdated") })
              );
            } else {
              _this.toast$.error(_this.$t("someThingWrong"));
            }
          }
        });
      }
    },
    selectIconWallet(icon, isActive) {
      this.form.icon = icon.value;
      this.form.icon_id = icon.id;
      isActive.value = false;
    },
    errorPasswordConfirm() {
      let errorMessage = "";
      if (this.v$.form.confirmPassword.required.$invalid && this.v$.form.confirmPassword.$dirty) {
        errorMessage = this.$t("validRequired", { field: this.$t("signUpPassword") });
      }
      if (this.v$.form.confirmPassword.sameAsPassword.$invalid && this.v$.form.confirmPassword.$dirty) {
        errorMessage = this.$t("validMatch", { field: this.$t("signUpPassword"), match: this.$t("confirmPassword") });
      }
      return errorMessage;
    },
  },
};
</script>
<style lang=""></style>
