<template>
  <ChatUI> </ChatUI>
</template>
<script>
import MoneyUI from "@/components/layouts/MoneyUI.vue";
import MainChat from "@/components/chat/MainChat.vue";
import ChatUI from "@/components/chat/ChatUI.vue";
export default {
  name: "ChatPage",
  components: {
    MoneyUI,
    MainChat,
    ChatUI,
  },
  created() {},
  mounted() {},
};
</script>
