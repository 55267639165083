import { useAuthStore } from "./authStore"
import { useWalletStore } from "./walletStore"
export default {

    logout(){
        var authStore = useAuthStore();
        var walletStore = useWalletStore();
        authStore.resetAuth();
        walletStore.resetWallet();
    }
}