<template>
  <v-app>
    <PublicNavbar></PublicNavbar>
    <v-main v-if="publicStore$.success">
      <slot></slot>
    </v-main>
    <ElementEmpty :loading="publicStore$.loading ? true : false" v-else></ElementEmpty>
  </v-app>
</template>
<script>
import walletService from "@/services/wallet.service";
import { useConfigStore } from "@/stores/configStore";
import { usePublicStore } from "@/stores/publicStore";
import { useTheme } from "vuetify/lib/framework.mjs";
import PublicNavbar from "../public/PublicNavbar.vue";
import ElementEmpty from "./elements/ElementEmpty.vue";
export default {
  name: "PublicUI",
  setup: () => ({
    configStore$: useConfigStore(),
    theme$: useTheme(),
    publicStore$: usePublicStore(),
  }),
  components: {
    PublicNavbar,
    ElementEmpty,
  },
  data: () => ({
    loading: true,
    UILang: "",
  }),
  mounted() {
    this.configStore$.loadLangAndTheme();
    this.loadTransactions();
  },
  methods: {
    async loadTransactions() {
      let params = this.$route.params.key;
      let querySplit = params.split("@");
      let email = querySplit[0];
      let walletId = querySplit[1];
      if (querySplit.length == 2 && params && email && !isNaN(walletId)) {
        let filter = {};
        if(this.$route.query){
           filter = this.$route.query;
        }
        this.publicStore$.setKeyPublic(email, walletId);
        this.publicStore$.loadingTransactionStore(email, walletId, filter);
      } else {
        this.publicStore$.setLoading(false);
      }
    },
  },
};
</script>
