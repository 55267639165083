<template lang="">
  <v-app>
    <v-main>
      <v-container class="mt-20">
        <v-card class="mx-auto my-16 pa-4" max-width="420">
          <Lang :text="true" class="text-right"></Lang>
          <v-card-title class="text-center py-10 text-uppercase">{{ $t("login") }}
          <p class="text-h5 font-weight-bold text-red" v-if="$route.query.messageAdminNoRole">
          {{$t('noPermission')}}
          </p>
           </v-card-title>

          <v-card-text>
            <v-form @submit.prevent="submit()">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    name="phoneOrEmail"
                    v-model="v$.phoneOrEmail.$model"
                    required
                    :error-messages="
                      v$.phoneOrEmail.required.$invalid && v$.phoneOrEmail.$dirty ? $t('validRequired', { field: $t('signUpPhoneOrEmail') }) : ''
                    "
                    @input="v$.phoneOrEmail.$touch()"
                    @blur="v$.phoneOrEmail.$touch()"
                    :label="$t('signUpPhoneOrEmail')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    name="password"
                    v-model="v$.password.$model"
                    required
                    :error-messages="v$.password.required.$invalid && v$.password.$dirty ? $t('validRequired', { field: $t('signUpPassword') }) : ''"
                    @input="v$.password.$touch()"
                    @blur="v$.password.$touch()"
                    :label="$t('signUpPassword')"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    :append-inner-icon="isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                    @click:append-inner="isPasswordVisible = !isPasswordVisible"
                  ></v-text-field>
                  <router-link class="float-right pa-0 mt-3" :to="{ name: 'login' }"> {{ $t("loginForgotPassword") }}? </router-link>
                </v-col>

                <v-col cols="12 px-3 py-0">
                  <VBtn :disabled="v$.$invalid" block type="submit">
                    <v-progress-circular :width="2" :size="25" v-if="loadingButtonSubmit" indeterminate color="saveRed"></v-progress-circular
                    ><span v-else>{{ $t("login") }}</span>
                  </VBtn>
                </v-col>
                <v-col class="mt-2 text-center">
                <b  v-if="$route.query.type == 'timeout'" class="text-red-darken-4 text-center text-uppercase">{{$t('401')}}</b>
                </v-col>
                <v-col cols="12" class="text-center text-base">
                  <span>{{ $t("signUpAskAccount") }}</span>
                  <RouterLink class="text-primary" :to="{ name: 'register' }">
                    <span class="ml-2">{{ $t("signUp") }} </span>
                  </RouterLink>
                </v-col>
                <!-- <v-col cols="12" class="d-flex align-center">
                  <VDivider />
                  <span class="mx-4">{{ $t("or") }}</span>
                  <VDivider />
                </v-col> -->
              </v-row>
            </v-form>
          </v-card-text>
          <!-- <v-card-text>
            <v-row>
              <v-col cols="12 pa-0 text-center">
                <v-btn class="mx-1" depressed outlined color elevation="0" fab icon small>
                  <v-icon class="text-15" x-large color="blue"> mdi-facebook </v-icon>
                </v-btn>
                <v-btn class="mx-1" depressed outlined color elevation="0" fab icon small>
                  <v-icon class="text-15" x-large color="red"> mdi-google </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text> -->
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { useTheme } from "vuetify";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import AuthService from "@/services/auth.service";
import { useAuthStore } from "@/stores/authStore";
import { encryptStorage } from "@/utils/encryptLocalStorage";
import { useToast } from "vue-toastification";
import { useWalletStore } from "@/stores/walletStore";
import Lang from "@/components/layouts/Lang.vue";

export default {
  components:{Lang},
  name: "LoginPage",
  setup: () => ({
    theme$: useTheme(),
    v$: useVuelidate(),
    auth$: useAuthStore(),
    toast$: useToast(),
    walletStore$:useWalletStore()
  }),
  validations() {
    return {
      phoneOrEmail: {
        required,
      },

      password: {
        required,
      },
    };
  },
  created(){
  },
  data() {
    return {
      phoneOrEmail: "",
      password: "",
      loadingButtonSubmit: false,
      errorLogin: "",
      isPasswordVisible: false,
      message: {
        type: "",
        value: "",
      },
    };
  },
  mounted() {
    // this.theme$.global.name.value = "dark";
    // this.$i18n.locale = "vn";
  },
  methods: {
    async submit() {
      this.errorLogin = "";
      this.loadingButtonSubmit = true;
      this.$Progress.start();
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$Progress.fail();
        this.loadingButtonSubmit = false;
        return;
      }
      this.loadingButtonSubmit = false;
      let userLogin = {
        phoneOrEmail: this.phoneOrEmail,
        password: this.password,
      };
      let loginRequest = await AuthService.login(userLogin);
      if (loginRequest.success) {
        let authSave = {
          ...loginRequest.auth,
          isLogin: true,
          accessToken: loginRequest.accessToken,
        };
        encryptStorage.setItem("auth", JSON.stringify(authSave));
        this.auth$.setAuth(authSave);
        await this.walletStore$.loadWalletsAndUser();
        this.$Progress.finish();
        if (this.$route.query.backURL) {
          this.$router.push({ name: this.$route.query.backURL });
        } else {
          this.$router.push({ name: "home" });
        }
      } else {
        this.toast$.error(this.$t("loginInvalidAccount"));
        this.$Progress.fail();
      }
      this.loadingButtonSubmit = false;
    },
  },
  watch: {},
};
</script>
<style>
.v-text-field .v-input__details {
  padding: 0 !important;
}
.v-progress-circular {
  margin: 0.5rem;
}
</style>
