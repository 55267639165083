<template lang="">
  <v-container>
    <h1>MainChat</h1>
  </v-container>
</template>
<script>
import { mapState } from "pinia";
import { useTheme } from "vuetify";
import { useAuthStore } from "@/stores/authStore";
import { useToast } from "vue-toastification";
import { useConfigStore } from "@/stores/configStore";
import ChatSidebar from "./ChatSidebar.vue";
export default {
  name: "MainChat",
  setup: () => ({
    theme$: useTheme(),
    auth$: useAuthStore(),
    toast$: useToast(),
  }),
  components: {ChatSidebar},
  data: () => ({}),
  created() {},
  mounted() {},
  methods: {},
  watch: {},
  computed: {},
};
</script>
