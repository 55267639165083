<template lang="">
  <!-- <v-slide-x-transition>
    <v-row max-height="" class="justify-center">
      <v-col
        :sm="window.width < 960 && !$route.params.walletId ? 8 : 4"
        cols="12"
        :lg="3"
        class="justify-center d-lg-block"
        :class="!$route.params.walletId ? 'd-block' : 'd-none'"
      > -->
        <v-card>
          <template v-slot:title>
            <div class="d-flex justify-space-between pa-3">
              <div class="justify-space-center">
                <span class="ml-5"
                  ><span> {{ $t("SideBarWallet") }}</span></span
                >
              </div>
            </div>
            <v-divider></v-divider>
          </template>
          <template v-slot:text>
            <div class="v-expansion-panel-text__wrapper">
              <v-table hover>
                <thead>
                  <tr>
                    <th style="font-size:1rem" class="text-left">{{ $t("WalletTableIcon") }}</th>
                    <th style="font-size:1rem" class="text-left">{{ $t("WalletTableName") }}</th>
                    <th  style="font-size:1rem" class="text-left">{{ $t("WalletTableTotalReal") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    :class="this.$route.params.walletId == item.id ? 'active-select' : ''"
                    @click="selectWallet(item)"
                    v-for="item in walletStore$.wallets"
                    :key="item.id"
                  >
                    <td>
                      <img
                        elevation="10"
                        class="hover-pointer"
                        style="width: 50px; height: 50px"
                        :src="`${baseUrl}/images/icons/${item.icon ? item.icon.value : 'default.png'}`"
                      />
                    </td>
                    <td>
                      <v-tooltip v-if="item.is_exclude" :text="$t('WalletTableStar')">
                        <template v-slot:activator="{ props }">
                          <v-icon v-bind="props" class="text-orange mr-1">mdi mdi-star</v-icon>
                        </template>
                      </v-tooltip>
                      <v-tooltip :text="item.name">
                        <template v-slot:activator="{ props }">
                          <i v-bind="props" @click="editWalet(item)"  class="money-main-wallet-name">{{ item.name }}</i>
                        </template>
                      </v-tooltip>
                    </td>
                    <td>
                      <p>
                        <span style="font-size:1rem">{{ formatNumber(item.money_total) }}</span>
                      </p>
                      <p>
                        <span style="font-size:1rem">{{ formatNumber(item.money_real) }}</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </div>
          </template>
        </v-card>
        <!-- <v-expansion-panels v-model="isShowPanelWallet">
          <v-expansion-panel>
            <v-expansion-panel-title :color="isShowPanelWallet == 0 ? 'primary' : ''">
              <span style="font-size: 18px">{{ $t("SideBarWallet") }}</span>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-table hover>
                <thead>
                  <tr>
                    <th class="text-left">{{ $t("WalletTableIcon") }}</th>
                    <th class="text-left">{{ $t("WalletTableName") }}</th>
                    <th class="text-left">{{ $t("WalletTableTotalReal") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    :class="this.$route.params.walletId == item.id ? 'active-select' : ''"
                    @click="selectWallet(item)"
                    v-for="item in walletStore$.wallets"
                    :key="item.id"
                  >
                    <td>
                      <img
                        elevation="10"
                        class="hover-pointer"
                        style="width: 50px; height: 50px"
                        :src="`${baseUrl}/images/icons/${item.icon.value}`"
                      />
                    </td>
                    <td>
                      <v-tooltip v-if="item.is_exclude" :text="$t('WalletTableStar')">
                        <template v-slot:activator="{ props }">
                          <v-icon v-bind="props" class="text-orange mr-1">mdi mdi-star</v-icon>
                        </template>
                      </v-tooltip>
                      <v-tooltip :text="'Edit ' + item.name">
                        <template v-slot:activator="{ props }">
                          <i v-bind="props" class="money-main-wallet-name">{{ item.name }}</i>
                        </template>
                      </v-tooltip>
                    </td>
                    <td>
                      <p>
                        <span>{{ formatNumber(item.money_total) }}</span>
                      </p>
                      <p>
                        <span>{{ formatNumber(item.money_real) }}</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels> -->
      <!-- </v-col>
      <slot> </slot>
    </v-row>
  </v-slide-x-transition> -->
</template>
<script>
import { useAuthStore } from "@/stores/authStore";
import { useWalletStore } from "@/stores/walletStore";
import { ROUTER_WALLET } from "@/useConfig";
import { useToast } from "vue-toastification";
import { useDisplay, useTheme } from "vuetify/lib/framework.mjs";

export default {
  setup: () => ({
    theme$: useTheme(),
    auth$: useAuthStore(),
    display$:useDisplay(),
    toast$: useToast(),
    walletStore$: useWalletStore(),
  }),
  name: "MoneyWallet",
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      isShowPanelWallet: 0,
      selectWalletPanel: "",
      //----------

      dialog: false,
      diaLogCategory: true,
      viewTimeline: true,
      formMoney: {
        category_id: 3,
      },
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {},
  methods: {
    displayWidthDetail() {
      let width = 0;
      let css = "";
      if (this.display$.smAndUp && this.display$.lgAndDown) {
        width = 38;
        css = `position:fixed;width:${width}%`;
        return css;
      }else if(this.display$.lgAndUp > 1280 ){
        width = 23;
        css = `position:fixed;width:${width}%`;
        return css;
      }
      return "width:100%";
    },
    editWalet(item){
     
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    selectWallet(item) {
      this.$emit("selectWallet", item);
      this.selectWalletPanel = item;
      this.walletStore$.setSelectedWallet(item);
    },
  },
  watch: {},
};
</script>
<style lang=""></style>
