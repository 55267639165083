<template>
  <PublicUI>
    <PublicMain></PublicMain>
  </PublicUI>
</template>
<script>
import PublicUI from "@/components/layouts/PublicUI.vue";
import PublicMain from "@/components/public/PublicMain.vue";
export default {
  name: "PublicPage",
  components: {
    PublicUI,PublicMain
  },
};
</script>
<style lang=""></style>
