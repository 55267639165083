<template lang="">
  <v-row class="justify-center mt-2">
    <v-col cols="12" lg="10">
      <v-card>
        <v-card-title class="">
          <span>{{ $t("adminIconList") }}</span>
          <v-btn
            @click="$router.push({ name: ROUTER_ADMIN_ICON_ADD })"
            class="float-right"
            color="green"
            variant="text"
            icon="mdi mdi-plus"
          ></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="query"
                @change="handleFilter('query')"
                :label="$t('MoneyTransactionSearch') + '...'"
                type="text"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-table hover v-if="!loading" block>
            <thead>
              <tr>
                <th class="text-left">{{ $t("adminTdIcon") }}</th>
                <th class="text-left">{{ $t("adminTdName") }}</th>
                <th class="text-left">{{ $t("adminTdValue") }}</th>
                <th class="text-left">{{ $t("adminTdCreated") }}</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in responses.data" :key="item.name">
                <td class="text-left">
                  <v-img width="50" height="50" :src="`${baseUrl}/images/icons/${item.value}`"> </v-img>
                </td>
                <td class="text-left">{{ item.name }}</td>
                <td class="text-left">{{ item.value }}</td>
                <td class="text-left">{{ dateFormatSaveMoney(item.created_at, true) }}</td>
                <td class="text-left">
                  <v-btn
                    @click="remove(item)"
                    class="ma-1"
                    color="error"
                    icon="mdi-delete"
                    variant="outlined"
                    size="small"
                  ></v-btn>
                  <v-btn
                    @click="edit(item)"
                    :variant="'outlined'"
                    color="info"
                    icon="mdi-pencil"
                    class="ma-1"
                    size="small"
                  ></v-btn>
                </td>
              </tr>
            </tbody>
          </v-table>
          <LoadingAdmin v-else></LoadingAdmin>
          <v-pagination
            v-if="responses.pagination.last_page > 1"
            style="width: 100%"
            v-model="filter.page"
            :length="responses.pagination.last_page"
          ></v-pagination>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import userService from "@/services/admins/user.service";
import iconService from "@/services/admins/icon.service";
import LoadingAdmin from "../elements/LoadingAdmin.vue";

export default {
  name: "UserList",
  components: {
    LoadingAdmin,
  },
  data() {
    return {
      loading: true,
      from: "",
      to: "",
      query: "",
      filter: {
        page: 1,
        state: "",
      },
      responses: {
        data: [],
        pagination: {
          current_page: 1,
          total: 0,
          per_page: 10,
          last_page: 1,
        },
      },
    };
  },
  created() {},
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      var response = await iconService.icon(this.filter);
      if (response.success) {
        this.mappingResponse(response.icons, this.responses);
      }
      this.loading = false;
    },
    async remove(item) {
      var _this = this;
      this.$swal({
        title: this.$t("swalSureField", { action: this.$t("delete") }),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("ok"),
        cancelButtonText: this.$t("no"),
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          var res = await iconService.remove(item.id);
          if (res.success) {
            _this.load();
            this.toast$.success(
              this.$t("fieldSuccess", { field: this.$t("adminTdIcon"), action: this.$t("actionRemove") })
            );
          } else {
            this.toast$.error(res.message);
          }
        }
      });
    },

    edit(item) {
      this.$router.push({ name: this.ROUTER_ADMIN_ICON_EDIT, params: { id: item.id } });
    },
    handleFilter(state, item = null) {
      this.filter.page = 1;
      if (state == "to") {
        if (this.to) {
          this.filter.to = this.to;
        } else {
          delete this.filter.to;
        }
      }

      if (state == "admin" || state == "normal" || state == "manager") {
        if (this.filter.state == state) {
          this.filter.state = state;
          delete this.filter.state;
        } else {
          this.filter.state = state;
        }
      }

      if (state == "from") {
        if (this.from) {
          this.filter.from = this.from;
        } else {
          delete this.filter.from;
        }
      }
      if (state == "query") {
        if (this.query) {
          this.filter.query = this.query;
        } else {
          delete this.filter.query;
        }
      }
      this.load();
    },
  },
  watch: {
    "filter.page": function () {
      this.load();
    },
  },
};
</script>
<style lang=""></style>
