import Api from "@/utils/axios";

export default {
  logs(query = null) {
    return Api.post("/log/doAction", { action: "logs", ...query });
  },
  delete(logId) {
    return Api.post("/log/doAction", { action: "delete", logId: logId });
  },
};
