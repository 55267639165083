import { defineStore } from "pinia";
import { encryptStorage } from "@/utils/encryptLocalStorage";
import walletService from "@/services/wallet.service";
import logService from "@/services/log.service";
import common from "@/utils/vueCommon";
import {router} from '@/routes'
const nameStore = "golden";

export const useGoldenStore = defineStore(nameStore, {
  state: () => ({
    selecting:false,
    selectedGoldType:'',
    arrayGoldeType:[],
    profitDate:'',
    source: "sjc",
    sourceData: [
      {
        name: "SJC",
        value: "sjc",
      },
      {
        name: "PNJ",
        value: "pnj",
      },
    ],
  }),

  actions: {
    setGoldType(value){
      this.selecting = value
    },
    setSelectedGoldType(value){
      this.selectedGoldType = value
    }
    
  },
});
