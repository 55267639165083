<template lang="">
  <v-app-bar density="compact" height="60" class="pt-1 pb-1">
    <template v-slot:prepend> 
      <v-list-item v-if="publicStore$.wallet"
       class="hover-pointer nav-item-money"  :prepend-avatar="`${baseUrl}/images/icons/${publicStore$.wallet.icon?.value}`" subtitle="">
        <v-list-item-title class=""><span class="b-350">{{publicStore$.wallet.name}}</span> </v-list-item-title>
        <v-list-item-subtitle>
          <ElementTotalMoney :styleText="``" :moneyTotal="publicStore$.wallet.money_total" :moneyReal="publicStore$.wallet.money_real"></ElementTotalMoney>
        </v-list-item-subtitle>
      </v-list-item>
    
    </template>
    <template v-slot:append>
      <v-btn class="" @click="changeTheme()" color="default" icon>
        <v-icon>{{ configStore$.theme == "light" ? "mdi-weather-night" : "mdi-weather-sunny" }}</v-icon>
      </v-btn>
      <v-btn class="" @click="changeLang()" color="default">{{ configStore$.lang == "en" ? "VN" : "EN" }}</v-btn>
    </template> </v-app-bar
  >>
</template>
<script>
import { useConfigStore } from "@/stores/configStore";
import { useTheme } from "vuetify/lib/framework.mjs";
import { DEFAULT_LANG, LANG_EN } from "@/useConfig";
import { usePublicStore } from "@/stores/publicStore";
import ElementTotalMoney from "../layouts/elements/ElementTotalMoney.vue";
export default {
  name: "PublicNavbar",
  components:{ElementTotalMoney},
  setup: () => ({
    configStore$: useConfigStore(),
    theme$: useTheme(),
    publicStore$: usePublicStore(),
  }),
  methods: {
    changeTheme() {
      if (this.configStore$.theme == "light") {
        this.configStore$.setTheme("dark");
      } else {
        this.configStore$.setTheme("light");
      }
      this.theme$.global.name.value = this.configStore$.theme;
    },
    changeLang() {
      if (this.configStore$.lang == DEFAULT_LANG) {
        this.configStore$.setLang(LANG_EN);
      } else {
        this.configStore$.setLang(DEFAULT_LANG);
      }
    },
  },
};
</script>
<style lang=""></style>
