<template lang="">
  <v-row class="justify-center mt-2">
    <v-col cols="12" lg="10">
      <v-card>
        <v-card-title class="d-flex align-center">
          <v-btn
            @click="$router.push({ name: ROUTER_ADMIN_USER })"
            class="float-left ma-0 pa-0"
            color="secondary"
            variant="text"
            icon="mdi mdi-arrow-left"
          ></v-btn>
          <span class="mt-1">{{ $t("edit") }} {{ $t("adminTdIcon") }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form @submit.prevent="submit()">
            <v-row class="pa-2">
              <v-col cols="12" sm="6">
                <v-file-input
                  v-model="form.file"
                  :error-messages="errorFile"
                  :label="$t('MoneyAddCreateFile')"
                  prepend-icon="mdi-paperclip"
                >
                  <template v-slot:selection="{ fileNames }">
                    <template v-for="fileName in fileNames" :key="fileName">
                      <v-chip size="small" label color="primary" class="me-2">
                        {{ fileName }}
                      </v-chip>
                    </template>
                  </template>
                </v-file-input>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="v$.form.name.$model"
                  :error-messages="
                    v$.form.name.required.$invalid && v$.form.name.$dirty
                      ? $t('validRequired', { field: $t('signUpName') })
                      : ''
                  "
                  @input="v$.form.name.$touch()"
                  @blur="v$.form.name.$touch()"
                  name="name"
                  clearable
                  :label="$t('signUpName') + '*'"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn type="submit" variant="flat" class="float-right" color="green">
                    <v-icon>mdi-plus</v-icon></v-btn
                  >
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { useTheme } from "vuetify";
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength, numeric } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
import { useAuthStore } from "@/stores/authStore";
import { useWalletStore } from "@/stores/walletStore";
import { useCommonStore } from "@/stores/commonStore";
import iconService from "@/services/admins/icon.service";
import { ROUTER_ADMIN_ICON, ROUTER_ADMIN_USER } from "@/useConfig";
export default {
  setup: () => ({
    theme$: useTheme(),
    v$: useVuelidate(),
    auth$: useAuthStore(),
    toast$: useToast(),
    walletStore$: useWalletStore(),
    commonStore$: useCommonStore(),
  }),
  data() {
    return {
      isPasswordVisible: false,
      loadingButtonSubmit: true,
      errorFile: "",
      form: {
        id:"",
        file: "",
        name: "",
      },
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
      },
    };
  },

  mounted() {
    if (this.$route.params.id) {
      this.form.id = this.$route.params.id;
      this.loadById();
    } else {
      this.$router.push({ name: ROUTER_ADMIN_USER });
    }
  },
  methods: {
    async loadById() {
      var res = await iconService.byId(this.$route.params.id);
      if (res.success) {
        this.form.name = res.icon.name;
        this.form.id = res.icon.id;
        this.form.value = res.icon.value;
      } else {
        this.$router.push({ name: ROUTER_ICON_USER });
      }
    },
    async submit() {
      var _this = this;
      const isFormCorrect = await this.v$.form.$validate();
      if (!isFormCorrect) {
        return;
      }

      if (this.errorFile) {
        return;
      }
      var body = {
        name: this.form.name,
        image: this.form.file,
        id: this.form.id,
      };

      var formData = new FormData();
      formData.name = body.name;
      formData.id = body.id;
      if(this.form.file){
        formData.image = body.image[0];
      }
      var update = await iconService.update(formData);
      if (update.success) {
        this.$router.push({ name: ROUTER_ADMIN_ICON });
        this.toast$.success(
          this.$t("fieldSuccess", { field: this.$t("adminTdIcon"), action: this.$t("actionUpdated") })
        );
      } else {
        this.toast$.error(update.message);
      }
    },
    selectIconWallet(icon, isActive) {
      this.form.icon = icon.value;
      this.form.icon_id = icon.id;
      isActive.value = false;
    },
    errorFileMessage(message) {
      this.errorFile = message;
    },
  },
  watch: {
    "form.file": function (new_value) {
      var flag = false;
      new_value.map((item) => {
        if (flag == false) {
          let filenameExtension = item.name.split(".").pop();
          if (!this.allowFileIcon.includes(filenameExtension)) {
            flag = true;
          }
        }
      });
      if (flag) {
        this.errorFileMessage(this.$t("validFile") + JSON.stringify(this.allowFileIcon));
      } else {
        this.errorFileMessage("");
      }
    },
  },
};
</script>
<style lang=""></style>
