<template lang="">
  <v-slide-y-transition>
    <v-container :fluid="true" class="">
      <div v-if="isShowTransaction" class="justify-center ma-auto">
        <v-row>
          <v-col
            transition="scroll-x-transition"
            cols="12"
            sm="8"
            lg="8"
            class="d-flex d-flex-start justify-center ma-auto"
          >
            <v-card style="width: 100%">
              <template v-slot:title>
                <div class="d-flex justify-space-between pa-2">
                  <div class="justify-space-center">
                    <span class="ml-5"
                      ><span
                        >{{ $t("SideBarWallet") }} {{ publicStore$.wallet.name }} {{ $t("of") }}
                        {{ publicStore$.user.name }}</span
                      ></span
                    >
                  </div>
                  <div class="justify-space-end">
                    <v-btn
                      :variant="'solo'"
                      color="secondary"
                      @click="handleButtonClearFilter()"
                      v-if="isShowButtonFilter"
                      icon="mdi-filter-remove-outline"
                    ></v-btn>
                  </div>
                </div>
                <v-divider></v-divider>
                <v-row class="pa-4">
                  <v-col cols="6">
                    <div class="d-flex justify-space-between">
                      <v-tooltip location="top">
                        <template v-slot:activator="{ props }">
                          <i
                            v-bind="props"
                            @click="filterTransaction('in')"
                            class="text-body-1 text-decoration-underline hover-pointer text-green money-hover-text"
                            >{{ $t("MoneyTransactionInflow") }}</i
                          >
                        </template>
                        <span>{{ $t("MoneyTransactionInflowDescription") }}</span>
                      </v-tooltip>
                      <p class="text-body-1 mb-2 text-green">
                        <span v-if="publicStore$.amountPositive > 0">+</span
                        >{{ formatNumber(publicStore$.amountPositive) }}
                      </p>
                    </div>

                    <div class="d-flex justify-space-between">
                      <v-tooltip location="top">
                        <template v-slot:activator="{ props }">
                          <i
                            v-bind="props"
                            @click="filterTransaction('out')"
                            class="text-body-1 text-decoration-underline hover-pointer text-red money-hover-text"
                            >{{ $t("MoneyTransactionOutflow") }}</i
                          >
                        </template>
                        <span>{{ $t("MoneyTransactionOutflowDescription") }}</span>
                      </v-tooltip>
                      <p class="text-body-1 text-red">
                        <span v-if="publicStore$.amountPositive < 0">-</span
                        >{{ formatNumber(publicStore$.amountNegative) }}
                      </p>
                    </div>
                    <div class="d-flex justify-space-between">
                      <span class="text-body-1"></span>
                      <span class="text-body-1 save-total-transaction text-cyan"
                        ><span class="text-cyan" v-if="publicStore$.amountPositive + publicStore$.amountNegative > 0"
                          >+</span
                        >{{ formatNumber(publicStore$.amountPositive + publicStore$.amountNegative) }}</span
                      >
                    </div>

                    <v-divider class="mt-2 mb-2"></v-divider>

                    <div cols="12" class="d-flex flex-wrap">
                      <v-tooltip v-for="item in publicStore$.filterIconByCategory" :key="item">
                        <template v-slot:activator="{ props }">
                          <img
                            @click="filterTransaction('category', item)"
                            v-bind="props"
                            elevation="10"
                            class="hover-pointer mr-1 ml-1"
                            :class="item.id == $route.query.cID ? 'select-transaction-category' : ''"
                            style="width: 50px; height: 50px"
                            :src="`${baseUrl}/images/icons/${item.icon.value}`"
                          />
                        </template>
                        <span v-if="configStore$.lang == LANG_EN">{{ item.nameTranslate }}</span>
                        <span v-else>{{ item.name }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="6" class="pr-0">
                    <v-row>
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="12" lg="6" class="pa-3">
                            <v-text-field
                              v-model="from"
                              @change="filterTransaction('from')"
                              :label="$t('MoneyTransactionFrom')"
                              type="date"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="6" class="pa-3">
                            <v-text-field
                              v-model="to"
                              @change="filterTransaction('to')"
                              :label="$t('MoneyTransactionTo')"
                              type="date"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="12" class="pa-3">
                            <v-text-field
                              v-model="query"
                              @change="filterTransaction('query')"
                              :label="$t('MoneyTransactionSearch') + '...'"
                              type="text"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" class="d-flex flex-wrap">
                            <v-tooltip location="bottom">
                              <template v-slot:activator="{ props }">
                                <v-btn
                                  @click="handleButtonFilter('day')"
                                  v-bind="props"
                                  c
                                  class="mr-2"
                                  icon="mdi mdi-calendar-today "
                                  size="small"
                                  :variant="$route.query.state == 'day' ? 'flat' : 'outlined'"
                                  color="info"
                                >
                                </v-btn>
                              </template>
                              <span>{{ $t("MoneyTransactionToday") }}</span>
                            </v-tooltip>

                            <v-tooltip>
                              <template v-slot:activator="{ props }">
                                <v-btn
                                  @click="handleButtonFilter('month')"
                                  tooltip="34343"
                                  v-bind="props"
                                  class="mr-2"
                                  icon="mdi mdi-calendar-month-outline"
                                  size="small"
                                  :variant="$route.query.state == 'month' ? 'flat' : 'outlined'"
                                  color="info"
                                >
                                </v-btn>
                              </template>
                              <span>{{ $t("MoneyTransactionMonth") }}</span>
                            </v-tooltip>
                          </v-col>

                          <v-col cols="12"> </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-divider></v-divider>
                  <v-col cols="12" v-if="publicStore$.transactions.length > 0">
                    <div cols="12" class="d-flex flex-wrap">
                      <v-tabs class="ma-auto" v-model="tabTransaction" color="saveRed" align-tabs="center">
                        <v-tab :value="1">News Feed</v-tab>
                        <v-tab :value="2">{{ $t("MoneyTransactionTimeline") }}</v-tab>
                        <v-tab :value="3">{{ $t("MoneyTransactionTable") }}</v-tab>
                        <!-- <v-tab :value="3">{{ $t("MoneyTransactionChart") }}</v-tab> -->
                      </v-tabs>
                    </div>
                  </v-col>
                  <ElementEmpty class="ma-auto" v-else></ElementEmpty>
                </v-row>
                <v-divider></v-divider>
                <!-- <div data-v-71b42fb1="" class="transaction-empty">
                    <div data-v-71b42fb1="" class="moneylover-empty">
                      <span data-v-71b42fb1="" class="smile all">^_^</span> <span data-v-71b42fb1="" class="note"></span>
                      <span data-v-71b42fb1="" class="tips"
                        ><span data-v-71b42fb1="" class="note">{{ $t("MoneyTransactionEmpty") }}</span></span
                      >
                    </div>
                  </div> -->
              </template>
              <template v-slot:text>
                <v-progress-circular
                  class="d-block ma-auto mb-3 mt-10"
                  :size="80"
                  v-if="publicStore$.loading"
                  color="amber"
                  indeterminate
                ></v-progress-circular>
                <!-- <v-progress-circular class="d-block ma-auto mb-3 mt-10" :size="80" color="amber" indeterminate></v-progress-circular> -->
                <v-window v-else :touch="false" v-model="tabTransaction">
                  <v-window-item :value="1"> </v-window-item>
                  <v-window-item :value="2">
                    <v-timeline align="start" side="end">
                      <v-timeline-item
                        class="save-active-hover"
                        block
                        style="width: 100%"
                        :dot-color="item.color"
                        v-for="item in publicStore$.transactions"
                        size="small"
                      >
                        <v-row
                          class="d-flex save-active-hover hover-pointer pt-2 pl-2 rounded"
                          :class="publicStore$.transaction?.id == item.id ? 'save-active-bg' : ''"
                        >
                          <v-col @click="detailTransaction(item)" cols="12">
                            <v-row>
                              <v-col cols="12" sm="5" lg="3" class="pa-0">
                                <strong class="mr-2 pa-0" style="width: 130px">
                                  {{ item.createdDate }}
                                  <p :class="`text-${item.color}`">
                                    <span :class="`text-${item.color}`" v-if="item.amount > 0">+</span
                                    >{{ formatNumber(item.amount) }}
                                  </p>
                                </strong>
                              </v-col>
                              <v-col cols="12" sm="7" lg="9" class="pa-0">
                                <strong>
                                  <span class="money-hover-text">
                                    <span class="text-cyan-lighten-2"> {{ item.category.name }}</span> /
                                    {{ item.note }}</span
                                  >
                                </strong>
                                <strong> </strong>

                                <div class="text-caption" style="white-space: break-spaces">
                                  <v-chip
                                    size="small"
                                    v-if="item.images.length > 0"
                                    class="ml-1 mr-1"
                                    color="info"
                                    label
                                    text-color="white"
                                  >
                                    <v-icon start icon="mdi-image"></v-icon>
                                    {{ item.images.length }}
                                  </v-chip>
                                  <v-chip
                                    size="small"
                                    color="info"
                                    v-if="item.description"
                                    class="ml-1 mr-1"
                                    label
                                    text-color="white"
                                  >
                                    <v-icon start class="ma-auto" icon="mdi-format-text"></v-icon>
                                  </v-chip>
                                  {{
                                    item.description?.length >= 30
                                      ? item.description.toString().substring(0, 30) + "...."
                                      : item.description
                                  }}
                                </div>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </v-timeline>
                  </v-window-item>
                  <v-window-item :value="3">
                    <MoneyTransactionTable
                      :modePublic="true"
                      @tableActionDetail="tableActionDetail($event)"
                    ></MoneyTransactionTable>
                  </v-window-item>
                </v-window>
              </template>
            </v-card>
          </v-col>
          <v-col class="justify-center ma-auto" cols="12" lg="8">
            <v-slide-x-transition>
              <v-row v-if="tabTransaction == 1" >
                <v-col v-for="item in publicStore$.transactions" cols="12">
                  <v-card class="mx-auto">
                    <v-list-item class="w-100">
                      <template v-slot:prepend>
                        <!-- <v-avatar
                        :image="
                          baseUrl + '/images/icons/' + (item.category.icon ? item.category.icon.value : defaultIcon)
                        "
                      ></v-avatar> -->
                      </template>
                      <v-list-item-title>
                        <strong>
                          <span class="text-cyan-lighten-2 text-subtitle-1 font-weight-medium">
                            {{ item.category.name }}</span
                          >
                          /
                          <span class="money-hover-text text-subtitle-1 font-weight-medium">{{ item.note }}</span>
                          <span class="ml-5" :class="`text-${item.color}`">
                            <span :class="`text-${item.color}`" v-if="item.amount > 0">+</span
                            >{{ formatNumber(item.amount) }}
                          </span>
                        </strong>
                      </v-list-item-title>
                      <v-list-item-subtitle> {{ item.createdDate }}</v-list-item-subtitle>
                      <template v-slot:append>
                        <v-menu location="start">
                          <template v-slot:activator="{ props }">
                            <v-btn color="secondary" icon="mdi-dots-horizontal" v-bind="props" variant="text"> </v-btn>
                          </template>
                          <v-list density="compact" class="pa-0">
                            <v-list-item
                              class="save-active-hover hover-pointer text-capitalize"
                              @click="remove(item)"
                              active-color="primary"
                              >{{ $t("delete") }}
                            </v-list-item>
                            <v-list-item
                              class="save-active-hover hover-pointer text-capitalize"
                              @click="edit(item)"
                              active-color="primary"
                              >{{ $t("edit") }}
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-list-item>
                    <v-row dense class="pl-4 pr-4 pb-4 pt-2">
                      <v-divider v-if="item.description" class=""></v-divider>
                      <v-card-text v-if="item.description" class="pl-0 pr-0 text-body-1"
                        >{{ item.description }}
                      </v-card-text>
                      <v-divider v-if="item.images.length > 0" class="mb-4"></v-divider>
                      <v-col class="" v-for="image in item.images" :key="image.id" :cols="image.flex">
                        <v-dialog
                          v-if="!allowINotmages.includes(filenameExtension(image.name))"
                          scrollable
                          width="1200"
                          transition="dialog-top-transition"
                        >
                          <template :key="img.id" v-slot:activator="{ props }">
                            <v-img
                              v-bind="props"
                              :src="`${baseUrl}/images/${
                                !allowINotmages.includes(filenameExtension(image.name)) ? 'transaction' : 'icons'
                              }/${!allowINotmages.includes(filenameExtension(image.name)) ? image.name : ''}`"
                              :lazy-src="`${baseUrl}/images/${
                                !allowINotmages.includes(filenameExtension(image.name)) ? 'transaction' : 'icons'
                              }/${!allowINotmages.includes(filenameExtension(image.name)) ? image.name : ''}`"
                              class="align-end"
                              max-height="350"
                            ></v-img>
                          </template>
                          <template :key="img.id" v-slot:default="{ isActive }">
                            <v-card class="ma-0 pa-0">
                              <v-card-text class="ma-0 pa-0">
                                <img
                                  :src="`${baseUrl}/images/${
                                    !allowINotmages.includes(filenameExtension(image.name)) ? 'transaction' : 'icons'
                                  }/${!allowINotmages.includes(filenameExtension(image.name)) ? image.name : ''}`"
                                  :lazy-src="`${baseUrl}/images/${
                                    !allowINotmages.includes(filenameExtension(image.name)) ? 'transaction' : 'icons'
                                  }/${!allowINotmages.includes(filenameExtension(image.name)) ? image.name : ''}`"
                                  class="align-end"
                                  style="width: 100%; height: 100%"
                                />
                                <v-icon
                                  @click="viewImage(baseUrl + '/images/transaction/' + image.name)"
                                  class="save-image-preview-download money-hover-text"
                                  >mdi mdi-download</v-icon
                                >
                                <v-icon
                                  @click="isActive.value = false"
                                  class="save-image-preview-close money-hover-text"
                                  >mdi mdi-close</v-icon
                                >
                              </v-card-text>
                            </v-card>
                          </template>
                        </v-dialog>

                        <v-col v-else cols="12" class="">
                          <a
                            target="_blank"
                            :href="`${baseUrl}/images/transaction/${image.name}`"
                            class="text-h5 text-uppercase mt-8 d-flex money-hover-text justify-center"
                          >
                            <v-icon>mdi-file-document</v-icon>{{ filenameExtension(image.name) }}
                          </a>
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-slide-x-transition>
          </v-col>
        </v-row>
      </div>
      <ElementEmpty v-else></ElementEmpty>
      <PublicDetail></PublicDetail>
    </v-container>
  </v-slide-y-transition>
</template>
<script>
import { ROUTER_PUBLIC } from "@/useConfig";
import PublicDetail from "./PublicDetail.vue";
import ElementEmpty from "../layouts/elements/ElementEmpty.vue";
import { useWalletStore } from "@/stores/walletStore";
import { useMoneyStore } from "@/stores/moneyStore";
import { usePublicStore } from "@/stores/publicStore";
import { useAuthStore } from "@/stores/authStore";
import { useToast } from "vue-toastification";
import { useConfigStore } from "@/stores/configStore";
import MoneyTransactionTable from "../money/MoneyTransactionTable.vue";
import { encryptStorage } from "@/utils/encryptLocalStorage";
export default {
  name: "PublicMain",
  components: { PublicDetail, ElementEmpty, MoneyTransactionTable },
  setup: () => ({
    toast$: useToast(),
    auth$: useAuthStore(),
    walletStore$: useWalletStore(),
    configStore$: useConfigStore(),
    moneyStore$: useMoneyStore(),
    publicStore$: usePublicStore(),
  }),
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      tabTransaction: "",
      showDetail: false,
      isShowTransaction: true,
      loadingPage: true,
      query: "",
      from: "",
      to: "",
      query: "",
      filter: {},
      buttonFilter: false,
      isShowButtonFilter: false,
    };
  },
  created() {},
  mounted() {
    if(encryptStorage.getItem("auth")){
      let valueLocal = encryptStorage.getItem("auth")
      if(valueLocal.setting?.tabTransaction){
        this.tabTransaction = valueLocal.setting?.tabTransaction 
      }
    }
    if(this.tabTransaction == ""){
      this.tabTransaction = 1
    }
    // this.tabTransaction = this.auth$.setting.tabTransaction 
    if (this.$route.query) {
      for (const item in this.$route.query) {
        this.isShowButtonFilter = true;
      }
    }
  },
  methods: {
    tableActionDetail(value) {
      this.detailTransaction(value);
      // this.publicStore$.setTransaction(value);
    },
    async detailTransaction(item) {
      this.publicStore$.setTransaction(item);
      this.publicStore$.setDialogDetail(true);
      console.log(this.publicStore$.dialogDetail);
    },
    filenameExtension(value) {
      return value.split(".").pop();
    },
    handleButtonFilter(state) {
      var oldState = this.buttonFilter;
      this.buttonFilter = state;
      let tempDate = new Date(this.dateFormatCurrent());
      if (state == this.filter.state) {
        delete this.filter.from;
        delete this.filter.to;
        delete this.filter.state;
        return this.$router.push({ name: ROUTER_PUBLIC, query: this.filter });
      }

      if (state == "month") {
        tempDate.setMonth(tempDate.getMonth() - 1);
        this.filter.to = this.dateFormatCurrent();
        this.filter.state = "month";
        this.filter.from = this.dateFormatSaveMoney(tempDate);
      } else if (state == "day") {
        tempDate.setDate(tempDate.getDate() - 2);
        this.filter.to = this.dateFormatCurrent();
        this.filter.from = this.dateFormatSaveMoney(tempDate);
        this.filter.state = "day";
      }
      return this.$router.push({ name: ROUTER_PUBLIC, query: this.filter });
    },
    handleButtonClearFilter() {
      this.isShowButtonFilter = false;
      this.publicStore$.clearFilter();
      this.$router.push({ name: ROUTER_PUBLIC, params: { key: this.$route.params.key } });
    },
    filterTransaction(state, item = null) {
      if (state == "category") {
        if (this.filter.cID == item.id) {
          delete this.filter.cID;
        } else {
          this.filter.cID = item.id;
        }
      }
      if (state == "in" || state == "out") {
        if (this.filter.io == state) {
          delete this.filter.io;
        } else {
          this.filter.io = state;
        }
      }
      if (state == "to") {
        if (this.to) {
          this.filter.to = this.to;
        } else {
          delete this.filter.to;
        }
      }

      if (state == "from") {
        if (this.from) {
          this.filter.from = this.from;
        } else {
          delete this.filter.from;
        }
      }
      if (state == "query") {
        if (this.query) {
          this.filter.query = this.query;
        } else {
          delete this.filter.query;
        }
      }
      let flag = false;
      for (const item in this.filter) {
        this.isShowButtonFilter = true;
        flag = true;
      }
      if (!flag) {
        this.isShowButtonFilter = false;
      }
      this.$router.push({ name: ROUTER_PUBLIC, query: this.filter });
      if (this.publicStore$.mail && this.publicStore$.walletId) {
        this.publicStore$.loadingTransactionStore(this.publicStore$.mail, this.publicStore$.walletId, this.filter);
      }
    },
  },
  watch: {
    "tabTransaction": function (new_value) {
      if(new_value)
      this.auth$.updateSetting({tabTransaction:new_value})
    },
    "$route.query": function (new_value) {
      // let flag = false;
      // if (new_value?.query) {
      //   for (const item in this.$route.query) {
      //     flag = true;
      //     this.isShowButtonFilter = true;
      //   }
      //   if (!flag) {
      //     this.isShowButtonFilter = false;
      //   }
      // }
    },
  },
};
</script>
<style lang=""></style>
