<template>
  <MoneyUI>
    <MainGolden></MainGolden>
  </MoneyUI>
</template>
<script>
import MoneyUI from "@/components/layouts/MoneyUI.vue";
import MainGolden from "@/components/goldens/MainGolden.vue";
export default {
  name: "GoldenPage",
  components: {
    MoneyUI,
    MainGolden,
  },
};
</script>
<style lang=""></style>
