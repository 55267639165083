<template lang="">
  <v-dialog v-model="diaLogCategory">
    <template v-slot:activator="{ props }">
      <v-combobox :error-messages="errorMessage" hint="Select a category for transaction" v-bind="props" label="Category" readonly v-model="category.name" prepend-inner-icon="mdi mdi-arrow-down" ></v-combobox>
    </template>
    <v-col cols="12" lg="3" class="ma-auto">
      <v-card>
        <v-card-title>
          <p><v-icon @click="diaLogCategory = false" size="25"> mdi mdi-close</v-icon> Select Category</p>
          <v-col cols="12" lg="8" sm="6" class="ma-auto">
            <v-text-field  prepend-inner-icon="mdi-magnify" label="Search category" class=""></v-text-field>
          </v-col>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="ma-auto">
              <v-list class="vb-dragger-styler light" height="400" lines="one">
                <v-list-item
                  @click="selectCategory(item)"
                  v-for="item in walletStore$.wallets"
                  :key="item.id"
                  class="hover-pointer nav-item-money pa-3 save-active-hover border-bottom-list"
                  :prepend-avatar="`/images/icons/${item.icon.value}`"
                  subtitle=""
                >
                  <v-list-item-title class=""
                    ><span class="b-350">{{ item.name }}</span></v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-col>
  </v-dialog>
</template>
<script>
import { useAuthStore } from "@/stores/authStore";
import { useWalletStore } from "@/stores/walletStore";
import { useToast } from "vue-toastification";
import { useTheme } from "vuetify/lib/framework.mjs";

export default {
  props: {errorMessage:String,categorySelectFirst:Object},
  setup: () => ({
    theme$: useTheme(),
    auth$: useAuthStore(),
    toast$: useToast(),
    walletStore$: useWalletStore(),
  }),
  name: "UISelectCategory",
  data: () => ({
    diaLogCategory: false,
    category: {
      id: "",
      name: "",
    },
  }),
  mounted(){
    if(this.categorySelectFirst){
        this.category.id = this.categorySelectFirst.id
        this.category.name = this.categorySelectFirst.name
    }
  },
  methods: {
    selectCategory(item) {
      if (item) {
        this.category.name = item.name;
        this.category.id = item.id;
        this.$emit("selectCategoryEvent", item);
        this.diaLogCategory = false;
      }
    },
  },
  watch: {
  
  },
};
</script>
<style lang=""></style>
