<template lang="">
  <v-expand-transition>
    <v-row class="ma-4 mt-7" v-if="displayForm">
      <v-col lg="6" xs="12" sm="12" md="8" xl="4" class="mx-auto">
        <v-card class="mx-auto">
          <v-card-title v-if="!isEditing">{{ $t("walletCreateTitle") }}</v-card-title>
          <v-card-title v-else>Edit wallet {{ walletEdit.name }}</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="submitCreateWallet()">
              <v-row class="pa-2">
                <v-col cols="3" class="text-center">
                  <v-dialog max-width="1000" scrollable transition="dialog-bottom-transition">
                    <template v-slot:activator="{ props }">
                      <img
                        v-bind="props"
                        @click="isShowModalIcons = !isShowModalIcons"
                        elevation="10"
                        class="hover-pointer hover-shadow"
                        style="width: 50px; height: 50px"
                        :src="`${baseUrl}/images/icons/${formWallet.icon}`"
                      />
                    </template>
                    <template v-slot:default="{ isActive }">
                      <!-- <v-card>
                    
                    </v-card> -->
                      <!-- <v-row>
                        <v-col cols="12" lg="6" class="ma-auto"> -->
                      <v-card>
                        <v-toolbar color="white">
                          <v-btn icon color="whiteColor" @click="isActive.value = false">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                          <v-toolbar-title>{{ $t("walletCreateTitleIcon") }}</v-toolbar-title>
                        </v-toolbar>
                        <v-divider></v-divider>
                        <v-card-text>
                          <v-row>
                            <v-col
                              @click="selectIconWallet(icon, isActive)"
                              v-for="icon in commonStore$.icons"
                              :key="icon.id"
                              cols="3"
                              md="1"
                              lg="1"
                              class="save-icon-image"
                              :class="formWallet.icon == icon.value ? 'save-icon-image-active' : ''"
                            >
                              <img
                                class="hover-pointer"
                                style="width: 50px; height: 50px"
                                :src="`${baseUrl}/images/icons/${icon.value}`"
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <!-- </v-col>
                      </v-row> -->
                    </template>
                  </v-dialog>
                </v-col>
                <v-col cols="9" lg="3" md="3" sm="3">
                  <v-select
                    v-model="formWallet.currency_id"
                    :items="commonStore$.currencies"
                    item-title="name"
                    item-value="id"
                    :label="$t('walletCreateCurrency')"
                  ></v-select>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="6">
                  <v-text-field
                    name="walletName"
                    v-model="v$.formWallet.walletName.$model"
                    :error-messages="
                      v$.formWallet.walletName.required.$invalid && v$.formWallet.walletName.$dirty
                        ? $t('validRequired', { field: $t('walletCreateName') })
                        : ''
                    "
                    @input="v$.formWallet.walletName.$touch()"
                    @blur="v$.formWallet.walletName.$touch()"
                    block
                    clearable
                    :label="$t('walletCreateName') + '*'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="6" md="6" xl="6">
                  <div
                    class="v-input v-input--horizontal v-input--density-comfortable v-input--dirty v-text-field v-field-focused"
                  >
                    <div class="v-input__control">
                      <div
                        class="v-field v-field--active v-field--dirty v-field--variant-outlined v-theme--light"
                        role="textbox"
                      >
                        <div class="v-field__field" data-no-activator="">
                          <input
                            name="money_total"
                            size="1"
                            type="text"
                            v-model="formWallet.money_total"
                            class="v-field__input v-field-focused"
                          />
                        </div>
                        <div class="v-field__outline">
                          <div class="v-field__outline__start"></div>
                          <div class="v-field__outline__notch">
                            <label
                              class="v-label v-field-label v-field-label--floating"
                              aria-hidden="true"
                              for="input-36"
                              >{{ $t("walletCreateInitialMoney") + "*" }}</label
                            >
                          </div>
                          <div class="v-field__outline__end"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <v-text-field
                    :error-messages="validateMoneyTotalError()"
                    name="money_total"
                    v-model="v$.formWallet.money_total.$model"
                    :label="$t('walletCreateInitialMoney') + '*'"
                  ></v-text-field> -->
                </v-col>
                <v-col xs="12" sm="6" lg="6" md="6" xl="6">
                  <div
                    class="v-input v-input--horizontal v-input--density-comfortable v-input--dirty v-text-field v-field-focused"
                  >
                    <div class="v-input__control">
                      <div
                        class="v-field v-field--active v-field--dirty v-field--variant-outlined v-theme--light"
                        role="textbox"
                      >
                        <div class="v-field__field" data-no-activator="">
                          <input
                            v-if="!formWallet.is_exclude"
                            name="money_total"
                            size="1"
                            type="text"
                            v-model="formWallet.money_real"
                            class="v-field__input v-field-focused"
                          />
                          <input
                            v-else
                            disabled
                            name="money_total"
                            size="1"
                            readonly
                            type="text"
                            :value="formWallet.money_real"
                            class="v-field__input v-field-focused"
                          />
                        </div>
                        <div class="v-field__outline">
                          <div class="v-field__outline__start"></div>
                          <div class="v-field__outline__notch">
                            <label
                              class="v-label v-field-label v-field-label--floating"
                              aria-hidden="true"
                              for="input-36"
                              >{{ $t("walletCreateRealMoney") + "*" }}</label
                            >
                          </div>
                          <div class="v-field__outline__end"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    name="expire_date"
                    v-model="v$.formWallet.expire_date.$model"
                    type="date"
                    clearable
                    :label="$t('walletCreateExpireDate')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-checkbox
                        hint="fdf"
                        v-bind="props"
                        v-model="formWallet.is_exclude"
                        :label="$t('walletExcludeCheckbox')"
                      ></v-checkbox>
                    </template>
                    <span>{{ $t("walletExclude") }}</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12">
                  <v-btn variant="text" @click="closeForm()" class="float-left" color="secondary">
                    <v-icon>mdi mdi-close</v-icon>
                  </v-btn>
                  <v-btn v-if="$route.query.edit" @click="backMoney()"  color="secondary"> <v-icon>mdi mdi-arrow-left</v-icon></v-btn>
                  <v-btn v-if="!isEditing" type="submit" class="float-right" color="primary">
                    <v-icon>mdi mdi-plus</v-icon>
                  </v-btn>
                  <v-btn v-else type="submit" class="float-right" color="info"> <v-icon>mdi mdi-pencil</v-icon></v-btn>
                  <v-btn
                  @click="convertToMainAccount()"
                  variant="outlined"
                  v-if="isEditing && formWallet.is_exclude"
                  class="float-right mr-4"
                  color="success"
                  >
                  <v-icon>mdi mdi-sync</v-icon>{{ $t("walletExcludeButton") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-expand-transition>
</template>
<script>
import walletService from "@/services/wallet.service";
import { useTheme } from "vuetify";
import { useVuelidate } from "@vuelidate/core";
import { required, minValue } from "@vuelidate/validators";
import { useAuthStore } from "@/stores/authStore";
import { useToast } from "vue-toastification";
import { useWalletStore } from "@/stores/walletStore";
import { useCommonStore } from "@/stores/commonStore";
import { ROUTER_MONEY } from "@/useConfig";
export default {
  name: "WalletForm",
  props: {
    displayForm: false,
    isEditing: false,
    walletEdit: false,
  },
  setup: () => ({
    theme$: useTheme(),
    v$: useVuelidate(),
    auth$: useAuthStore(),
    toast$: useToast(),
    walletStore$: useWalletStore(),
    commonStore$: useCommonStore(),
  }),
  components: {},
  data: () => ({
    isShowModalIcons: false,
    exportValueModalIcon: "",
    icons: [],
    currencies: [],
    formWallet: {
      walletName: "",
      icon: "icon_109.png",
      icon_id: "109",
      currency_id: "",
      money_total: "0",
      money_real: "0",
      expire_date: "",
      is_exclude: false,
    },
    value: "1234567.89",
    label: "Value",
    placeholder: " ",
    readonly: false,
    disabled: false,
    outlined: true,
    clearable: true,
    valueWhenIsEmpty: "",
    options: {
      locale: "pt-BR",
      prefix: "R$",
      suffix: "",
      length: 11,
      precision: 2,
    },
    properties: {
      hint: "my hint",
      // You can add other v-text-field properties, here.
    },
  }),
  validations() {
    return {
      formWallet: {
        walletName: {
          required,
        },
        money_total: {
          required,
        },
        money_real: {
          required,
        },
        expire_date: {},
      },
    };
  },
  mounted() {
    this.commonStore$.currencies.map((item) => {
      if (item.value == "vnd") {
        this.formWallet.currency_id = item.id;
      }
    });
  },
  methods: {
    async loadIcons() {
      var _this = this;
      var iconsRequest = await walletService.getDataCreate();
      if (iconsRequest.success) {
        this.icons = iconsRequest.icons;
        this.currencies = iconsRequest.currencies;
        if (this.currencies.length > 0) {
          this.currencies.map((item) => {
            if (item.value == "vnd") {
              _this.formWallet.currency_id = item.id;
            }
          });
        }
      }
    },
    async submitCreateWallet() {
      const isFormCorrect = await this.v$.formWallet.$validate();

      if (this.formWallet.icon == this.defaultIcon) {
        this.toast$.error(this.$t("mustChangeIcon"));
        return;
      }
      if (!isFormCorrect) {
        return;
      }
      this.formWallet.money_total = this.removeAllSpecialCharacter(this.formWallet.money_total);
      this.formWallet.money_real = this.removeAllSpecialCharacter(this.formWallet.money_real);
      var submitWallet = this.formWallet;
      if (!this.isEditing) {
        var walletSaveRequest = await walletService.save(submitWallet);
        if (walletSaveRequest.success) {
          this.formWallet = {
            icon: "icon_109.png",
            icon_id: "109",
            currency_id: 1,
            money_total: 0,
            money_real: 0,
            expire_date: "",
          };
          this.toast$.success(this.$t("walletCreateSuccess"));
          this.$emit("createdSuccess");
        } else {
          this.toast$.error(this.$t("walletCreateError"));
        }
      } else {
        if (this.walletEdit?.id) {
          var walletEditRequest = await walletService.update(submitWallet, this.walletEdit.id);
          if (walletEditRequest.success) {
            this.toast$.success(
              this.$t("fieldSuccess", { field: this.$t("SideBarWallet"), action: this.$t("actionEdited") })
            );
            this.$emit("createdSuccess");
          }
        } else {
          this.toast$.error(this.$t("someThingWrong"));
        }
      }
      this.walletStore$.loadWalletsAndUser();
      this.walletStore$.loadLogs();
    },
    backMoney(){
      if(this.$route.query.edit){
        this.$router.push({ name: ROUTER_MONEY, params: { walletId: this.walletEdit.id} });
      }
    },
    convertToMainAccount() {
      var _this = this;
      if (this.isEditing) {
        this.$swal({
          title: this.$t("swalSure"),
          text: this.$t("walletExcludeConfirm"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("ok"),
          cancelButtonText: this.$t("no"),
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            var requestConvert = await walletService.convertRemainMoney(this.walletEdit.id);
            if (requestConvert.success) {
              this.toast$.success(
                this.$t("fieldSuccess", { field: this.$t("balanceAccount"), action: this.$t("actionConverted") })
              );
              _this.formWallet.money_total = requestConvert.moneyTotal;
              _this.walletStore$.loadWalletsAndUser();
              this.$emit("createdSuccess");
            } else {
              this.toast$.error(this.$t("someThingWrong"));
            }
          }
        });
      }
    },
    closeForm() {
      this.$emit("closeForm");
    },
    selectIconWallet(icon, isActive) {
      this.formWallet.icon = icon.value;
      this.formWallet.icon_id = icon.id;
      isActive.value = false;
    },
    validateMoneyTotalError() {
      let errorMessage = "";
      if (this.v$.formWallet.money_total.required.$invalid && this.v$.formWallet.money_total.$dirty) {
        errorMessage = this.$t("validRequired", { field: this.$t("walletCreateInitialMoney") });
      }
      return errorMessage;
    },
    changeVtextfield(e) {
      this.formWallet.money_total = 55;
    },
  },
  watch: {
    "formWallet.money_total": function (new_value) {
      this.formWallet.money_total = this.formatCurrency(new_value);
    },
    "formWallet.money_real": function (new_value) {
      this.formWallet.money_real = this.formatCurrency(new_value);
    },
    walletEdit: function (new_value, old_value) {
      if (this.isEditing) {
        this.formWallet.walletName = new_value.name;
        this.formWallet.money_total = new_value.money_total;
        this.formWallet.money_real = new_value.money_real;
        this.formWallet.icon_id = new_value.icon ? new_value.icon.id : null;
        this.formWallet.icon = new_value.icon ? new_value.icon.value : this.defaultIcon;
        this.formWallet.expire_date = new_value.expire_date;
        this.formWallet.is_exclude = new_value.is_exclude == 1 ? true : false;
      } else {
        this.formWallet = {
          icon: "icon_109.png",
          icon_id: "109",
          currency_id: 1,
          money_total: 0,
          money_real: 0,
          expire_date: "",
        };
      }
    },
  },
};
</script>
<style lang=""></style>
