import { defineStore } from "pinia";
import walletService from "@/services/wallet.service";
const nameStore = "common";

export const useCommonStore = defineStore(nameStore, {
  state: () => ({
    icons:[],
    currencies:[]
  }),

  actions: {
    async loadCommonData(){
      var _this = this;
      var iconsRequest = await walletService.getDataCreate();
      if (iconsRequest.success) {
        this.icons = iconsRequest.icons;
        this.currencies = iconsRequest.currencies;
        if (this.currencies.length > 0) {
          // this.currencies.map((item) => {
          //   if (item.value == "vnd") {
          //     _this.formWallet.currency_id = item.id;
          //   }
          // });
        }
      }
    }
  },
});
