<template>
  <MoneyUI>
    <MoneyMain></MoneyMain>
  </MoneyUI>
</template>
<script>
import MoneyUI from "@/components/layouts/MoneyUI.vue";
import MoneyMain from "@/components/money/MoneyMain.vue";
export default {
  name: "MoneyPage",
  components: {
    MoneyUI,MoneyMain
  },
  created() {},
  mounted() {},
};
</script>
<style lang=""></style>
