<template lang="">
  <v-row class="" v-if="displayForm">
    <v-col cols="12" class="">
      <v-card :disabled="goldenStore.selecting">
        <v-card-title>{{ $t(isEditing ? "Edit" : "Create") }} Lưu Trữ</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="submitCreateWallet()">
            <v-row class="pa-2">
              <v-col lg="6" xs="4" sm="6" md="6" cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      name="title"
                      @click="selectTypeGold()"
                      readonly
                      class="text-left"
                      v-model="v$.form.typeGold.$model"
                      :error-messages="
                        v$.form.typeGold.required.$invalid && v$.form.typeGold.$dirty ? $t('validRequired', { field: $t('goldenTFType') }) : ''
                      "
                      @input="v$.form.typeGold.$touch()"
                      @blur="v$.form.typeGold.$touch()"
                      block
                      :label="$t('goldenTFType') + '*'"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="6" sm="6">
                <v-row>
                  <v-col cols="12" lg="6" sm="6">
                    <v-text-field
                      name="title"
                      class="text-left"
                      v-model="v$.form.name.$model"
                      :error-messages="
                        v$.form.name.required.$invalid && v$.form.name.$dirty ? $t('validRequired', { field: $t('goldenTFtitle') }) : ''
                      "
                      @input="v$.form.name.$touch()"
                      @blur="v$.form.name.$touch()"
                      block
                      clearable
                      :label="$t('goldenTFtitle') + '*'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" sm="6">
                    <v-text-field
                      name="title"
                      class="text-left"
                      type="number"
                      v-model="v$.form.quantity.$model"
                      :error-messages="
                        v$.form.quantity.required.$invalid && v$.form.quantity.$dirty ? $t('validRequired', { field: $t('goldenQuantity') }) : ''
                      "
                      @input="v$.form.quantity.$touch()"
                      @blur="v$.form.quantity.$touch()"
                      block
                      :label="$t('goldenQuantity') + '*'"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" lg="6" sm="6">
                    <div class="v-input v-input--horizontal v-input--density-comfortable v-input--dirty v-text-field v-field-focused">
                      <div class="v-input__control">
                        <div class="v-field v-field--active v-field--dirty v-field--variant-outlined v-theme--light" role="textbox">
                          <div class="v-field__field" data-no-activator="">
                            <input name="rootBuy" size="1" type="text" v-model="form.rootBuy" class="v-field__input v-field-focused" />
                          </div>
                          <div class="v-field__outline">
                            <div class="v-field__outline__start"></div>
                            <div class="v-field__outline__notch">
                              <label class="v-label v-field-label v-field-label--floating" aria-hidden="true" for="input-36">{{
                                $t("goldenTFRootBuy") + "*"
                              }}</label>
                            </div>
                            <div class="v-field__outline__end"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" lg="6" sm="6">
                    <div class="v-input v-input--horizontal v-input--density-comfortable v-input--dirty v-text-field v-field-focused">
                      <div class="v-input__control">
                        <div class="v-field v-field--active v-field--dirty v-field--variant-outlined v-theme--light" role="textbox">
                          <div class="v-field__field" data-no-activator="">
                            <input name="rootSell" size="1" type="text" v-model="form.rootSell" class="v-field__input v-field-focused" />
                          </div>
                          <div class="v-field__outline">
                            <div class="v-field__outline__start"></div>
                            <div class="v-field__outline__notch">
                              <label class="v-label v-field-label v-field-label--floating" aria-hidden="true" for="input-36">{{
                                $t("goldenTFRootSell") + "*"
                              }}</label>
                            </div>
                            <div class="v-field__outline__end"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-row>
                  <v-col cols="12" lg="6" sm="6">
                    <div class="v-input v-input--horizontal v-input--density-comfortable v-input--dirty v-text-field v-field-focused">
                      <div class="v-input__control">
                        <div class="v-field v-field--active v-field--dirty v-field--variant-outlined v-theme--light" role="textbox">
                          <div class="v-field__field" data-no-activator="">
                            <input
                              :disabled="form.isCurrent"
                              name="currentBuy"
                              size="1"
                              type="text"
                              v-model="form.currentBuy"
                              class="v-field__input v-field-focused"
                            />
                          </div>
                          <div class="v-field__outline">
                            <div class="v-field__outline__start"></div>
                            <div class="v-field__outline__notch">
                              <label class="v-label v-field-label v-field-label--floating" aria-hidden="true" for="input-36">{{
                                $t("goldenTFCurrentBuy") + "*"
                              }}</label>
                            </div>
                            <div class="v-field__outline__end"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" lg="6" sm="6">
                    <div class="v-input v-input--horizontal v-input--density-comfortable v-input--dirty v-text-field v-field-focused">
                      <div class="v-input__control">
                        <div class="v-field v-field--active v-field--dirty v-field--variant-outlined v-theme--light" role="textbox">
                          <div class="v-field__field" data-no-activator="">
                            <input
                              :disabled="form.isCurrent"
                              name="currentSell"
                              size="1"
                              type="text"
                              v-model="form.currentSell"
                              class="v-field__input v-field-focused"
                            />
                          </div>
                          <div class="v-field__outline">
                            <div class="v-field__outline__start"></div>
                            <div class="v-field__outline__notch">
                              <label class="v-label v-field-label v-field-label--floating" aria-hidden="true" for="input-36">{{
                                $t("goldenTFCurrentSell") + "*"
                              }}</label>
                            </div>
                            <div class="v-field__outline__end"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" lg="4" sm="6">
                    <v-text-field
                      name="dateCreate"
                      :error-messages="
                        v$.form.createdAt.required.$invalid && v$.form.createdAt.$dirty ? $t('validRequired', { field: $t('goldenTFCreatedAt') }) : ''
                      "
                      v-model="v$.form.createdAt.$model"
                      @input="v$.form.createdAt.$touch()"
                      @blur="v$.form.createdAt.$touch()"
                      clearable
                      :label="$t('goldenTFCreatedAt') + '*'"
                      type="datetime-local"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="7" lg="3" sm="3">
                    <v-switch v-model="form.isCurrent" :label="`${$t('goldenSWCurrent')}`"></v-switch>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-btn v-if="!isEditing" type="submit" class="float-right" color="primary">
                  <v-icon>mdi mdi-plus</v-icon>
                </v-btn>
                <v-btn v-else type="submit" class="float-right" color="info"> <v-icon>mdi mdi-pencil</v-icon></v-btn>
                <v-btn variant="outlined" @click="closeForm()" class="float-right mr-2" color="secondary">
                  <v-icon>mdi mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import goldenServices from "@/services/golden.service";
import { useTheme } from "vuetify";
import { useVuelidate } from "@vuelidate/core";
import { required, minValue } from "@vuelidate/validators";
import { useAuthStore } from "@/stores/authStore";
import { useToast } from "vue-toastification";
import { useWalletStore } from "@/stores/walletStore";
import { useCommonStore } from "@/stores/commonStore";
import { useGoldenStore } from "@/stores/goldenStore";

export default {
  name: "GoldenForm",
  props: {
    displayForm: false,
    isEditing: false,
    edit: false,
  },
  setup: () => ({
    theme$: useTheme(),
    v$: useVuelidate(),
    auth$: useAuthStore(),
    toast$: useToast(),
    walletStore$: useWalletStore(),
    commonStore$: useCommonStore(),
    goldenStore: useGoldenStore(),
  }),
  components: {},
  data: () => ({
    isShowModalIcons: false,
    exportValueModalIcon: "",
    icons: [],
    currencies: [],

    source: "SJC",
    form: {
      name: "",
      quantity: "",
      typeGold: "",
      rootSell: 0,
      rootBuy: 0,
      currentBuy: 0,
      currentSell: 0,
      createdAt: "",
      isCurrent: true,
    },
    value: "1234567.89",
    label: "Value",
    placeholder: " ",
    readonly: false,
    disabled: false,
    outlined: true,
    clearable: true,
    valueWhenIsEmpty: "",
  
  }),
  validations() {
    return {
      form: {
        name: {
          required,
        },
        typeGold: {
          required,
        },
        quantity: {
          required,
        },
        rootSell: {
          required,
        },
        createdAt: {
          required,
        },
        rootBuy: {
          required,
        },
        currentBuy: {},
        currentSell: {},
        isCurrent: {},
      },
    };
  },
  mounted() {
    this.goldenStore.setGoldType(false);
    this.goldenStore.selectedGoldType = "";
    this.form.createdAt = this.dateFormatCurrent(true);
  },
  methods: {
    async submitCreateWallet() {
      const isFormCorrect = await this.v$.form.$validate();
      if (!isFormCorrect) {
        return;
      }
      let body = {
        name: this.form.name,
        type: this.form.typeGold,
        quantity: this.form.quantity,
        createdAt: this.form.createdAt,
        source: this.goldenStore.selectedGoldType.source,
        rootSell: this.removeAllSpecialCharacter(this.form.rootSell),
        rootBuy: this.removeAllSpecialCharacter(this.form.rootBuy),
        currentBuy: this.removeAllSpecialCharacter(this.form.currentBuy),
        currentSell: this.removeAllSpecialCharacter(this.form.currentSell),
        isCurrent: this.form.isCurrent,
      };
      if (!this.isEditing) {
        var save = await goldenServices.save(body);
        if (save.success) {
          this.toast$.success(this.$t("fieldSuccess", { field: this.$t("goldenStore"), action: this.$t("actionCreated") }));
          this.$emit("createdSuccess");
        }
      } else {
        body.id = this.edit.id;
        var update = await goldenServices.update(body);
        if (update.success) {
          this.toast$.success(this.$t("fieldSuccess", { field: this.$t("goldenStore"), action: this.$t("actionUpdated") }));
          this.$emit("createdSuccess");
        }
      }
    },
    selectTypeGold() {
      this.goldenStore.setGoldType(true);
    },
    closeForm() {
      this.$emit("closeForm");
    },
  },
  watch: {
    "form.rootSell": function (new_value) {
      this.form.rootSell = this.formatCurrency(new_value);
    },
    "form.rootBuy": function (new_value) {
      this.form.rootBuy = this.formatCurrency(new_value);
    },
    "form.currentSell": function (new_value) {
      this.form.currentSell = this.formatCurrency(new_value);
    },
    "form.currentBuy": function (new_value) {
      this.form.currentBuy = this.formatCurrency(new_value);
    },
    "goldenStore.selectedGoldType": function (new_value, old_value) {
      this.form.typeGold = new_value.type;
      if (this.form.isCurrent && this.displayForm) {
        if (this.goldenStore.selectedGoldType) {
          if (this.goldenStore.selectedGoldType.currentBuy && this.goldenStore.selectedGoldType.currentSell) {
            this.form.currentBuy = this.goldenStore.selectedGoldType.currentBuy;
            this.form.currentSell = this.goldenStore.selectedGoldType.currentSell;
          } else {
            this.form.currentBuy = this.goldenStore.selectedGoldType.buyValue;
            this.form.currentSell = this.goldenStore.selectedGoldType.sellValue;
          }
        }
      }
    },
    edit: function (new_value, old_value) {
      if (this.isEditing) {
        this.form.name = new_value.name;
        this.form.quantity = new_value.quantity;
        this.form.typeGold = new_value.type;
        this.form.rootSell = new_value.rootSell;
        this.form.rootBuy = new_value.rootBuy;
        this.form.currentBuy = new_value.currentBuy;
        this.form.currentSell = new_value.currentSell;
        this.form.isCurrent = new_value.isCurrent == 1 ? true : false;
        this.form.createdAt = this.dateFormatSaveMoney(new_value.created_at, true);
      } else {
        this.form = {
          name: "",
          quantity: "",
          typeGold: "",
          rootSell: 0,
          rootBuy: 0,
          currentBuy: 0,
          currentSell: 0,
          createdAt: this.dateFormatCurrent(true),
          isCurrent: true,
        };
      }
    },
  },
};
</script>
<style lang=""></style>
