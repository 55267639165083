<template>
  <MoneyUI>
    <v-container fill-height>
      <v-row justify="center" align="center">
        <v-col cols="12" class="ma-auto text-center">
          <br />
          <br />
          <br />
          <br />
          <div v data-v-71b42fb1="" class="transaction-empty">
            <div data-v-71b42fb1="" class="moneylover-empty">
              <span data-v-71b42fb1=""  class="text-red smile all">~_~</span> <span data-v-71b42fb1="" class="note"></span>
              <span data-v-71b42fb1="" class="tips"
                ><span data-v-71b42fb1="" class="note">
                  <p class="text-cyan">{{ $t("noPermission") }}</p></span
                ></span
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </MoneyUI>
</template>
<script>
import AccountMain from "@/components/account/AccountMain.vue";
import MoneyUI from "@/components/layouts/MoneyUI.vue";
export default {
  name: "403Page",
  components: {
    MoneyUI,
    AccountMain,
  },
};
</script>
<style lang=""></style>
