<template>
  <div>
    <span :class="styleText"
      ><span :class="returnClassCssMoneyTotal">{{ formatNumber(moneyTotal) }}</span></span
    >
    <span :class="`${styleText} mr-2 ml-2`">/</span>
    <span :class="styleText"
      ><span :class="returnClassCssMoneyReal">{{ formatNumber(moneyReal) }}</span></span
    >
  </div>
</template>

<script>

export default {
  name: "ElementTotalMoney",
  props: { styleText: String, moneyTotal: Number, moneyReal: Number },
  data() {
    return {
      money_total: 0,
      money_real: 0,
    };
  },
  mounted() {
    this.money_total = this.moneyTotal;
    this.money_real = this.moneyReal;
  },
  computed: {
    returnClassCssMoneyTotal() {
      var total = parseInt(this.moneyTotal);
      var real = parseInt(this.moneyReal);
      if (total < 0) {
        return "text-info";
      } else {
        if(total > real){
            return "text-red";
        }else{
            return "text-info";
        }
      }
    },
    returnClassCssMoneyReal() {
      var real = parseInt(this.moneyReal);
      var total = parseInt(this.moneyTotal);
      if (real < 0) {
        return "text-red";
      } else {
        if(real < total){
            return "text-orange";
        }else{
            return "text-green";
        }
      }
    },
  },
  watch: {
    moneyTotal: function (new_value) {
      this.money_total = new_value;
    },
    moneyReal: function (new_value) {
      this.money_real = new_value;
    },
  },
};
</script>
<style lang=""></style>
